import Library from '@/entities/Library';
import LibraryService from '@/services/Library';

const state = {
  list: [],
};

const getters = {
  list(state) {
    return state.list;
  },

  get: (state) => (id) => {
    for (let item of state.list) {
      if (item.id === id) {
        return item;
      }
    }

    return null;
  },

  getVideo: (state) => (videoId) => {
    for (var i = 0; i < state.list.length; i++) {
      for (var j = 0; j < state.list[i].videos.length; j++) {
        if (state.list[i].videos[j].id === videoId) {
          return state.list[i].videos[j];
        }
      }
    }

    return null;
  },

  libraryHasVideo: (state) => (library, video) => {
    for (var i = 0; i < state.list.length; i++) {
      if (state.list[i].id === library.id) {
        for (var j = 0; j < state.list[i].videos.length; j++) {
          if (state.list[i].videos[j].id === video.id) {
            return true;
          }
        }
      }
    }

    return false;
  },
};

const actions = {
  getList(store) {
    return LibraryService.getList().then((resp) => {
      resp = resp.map(item => new Library(item));

      resp.forEach(responsedEntity => {
        if (!store.getters.get(responsedEntity.id)) {
          store.commit('addToList', responsedEntity);
        } else {
          store.commit('updateInList', responsedEntity);
        }
      })

      return resp;
    });
  },

  get(store, id) {
    return LibraryService.get(id).then((resp) => {
      const responsedEntity = new Library(resp);

      if (!store.getters.get(responsedEntity.id)) {
        store.commit('addToList', responsedEntity);
      } else {
        store.commit('updateInList', responsedEntity);
      }

      return responsedEntity;
    });
  },

  getStat(store, { id, from, to }) {
    return LibraryService.getStat(id, from, to).then((resp) => {
      return resp.stat;
    });
  },

  save(store, entity) {
    let promise;
    if (entity.isNew) {
      promise = LibraryService.create(entity);
    } else {
      promise = LibraryService.update(entity);
    }

    return promise.then((resp) => {
      const responsedEntity = new Library(resp);

      if (entity.isNew) {
        store.commit('addToList', responsedEntity);
      } else {
        store.commit('updateInList', responsedEntity);
      }

      return responsedEntity;
    });
  },

  delete(store, entity) {
    return LibraryService.delete(entity);
  },

  // Video actions
  addVideoToLibrary(store, { library, video }) {
    store.commit('addVideoToLibrary', { library, video });
  },

  updateVideoInLibrary(store, { library, video }) {
    store.commit('updateVideoInLibrary', { library, video });
  },

  deleteVideoFromLibrary(store, { library, video }) {
    store.commit('deleteVideoFromLibrary', { library, video });
  },

  deleteVideoFromLibraryByVideoId(store, { library, videoId }) {
    store.commit('deleteVideoFromLibraryByVideoId', { library, videoId });
  },
};

const mutations = {
  setList(state, list) {
    state.list = [];

    for (let item of list) {
      if (item instanceof Library) {
        state.list.push(item);
      } else {
        state.list.push(new Library(item));
      }
    }
  },

  addToList(state, entity) {
    state.list.push(entity);
  },

  updateInList(state, entity) {
    for (let item of state.list) {
      if (item.id !== entity.id) {
        continue;
      }

      item.updateWith(entity);

      break;
    }
  },

  deleteFromList(state, entity) {
    for (var i = 0; i < state.list.length; i++) {
      if (state.list[i].id === entity.id) {
        state.list.splice(i, 1);
      }
    }
  },

  // Video mutations
  addVideoToLibrary(state, { library, video }) {
    for (var i = 0; i < state.list.length; i++) {
      if (state.list[i].id === library.id) {
        state.list[i].videos.unshift(video);
      }
    }
  },

  updateVideoInLibrary(state, { library, video }) {
    for (var i = 0; i < state.list.length; i++) {
      if (state.list[i].id === library.id) {
        for (var j = 0; j < state.list[i].videos.length; j++) {
          if (state.list[i].videos[j].id === video.id) {
            state.list[i].videos[j].updateWith(video);
          }
        }
      }
    }
  },

  deleteVideoFromLibrary(state, { library, video }) {
    for (var i = 0; i < state.list.length; i++) {
      if (state.list[i].id === library.id) {
        for (var j = 0; j < state.list[i].videos.length; j++) {
          if (state.list[i].videos[j].id === video.id) {
            state.list[i].videos.splice(j, 1);
          }
        }
      }
    }
  },

  deleteVideoFromLibraryByVideoId(state, { library, videoId }) {
    for (var i = 0; i < state.list.length; i++) {
      if (state.list[i].id === library.id) {
        for (var j = 0; j < state.list[i].videos.length; j++) {
          if (state.list[i].videos[j].id === videoId) {
            state.list[i].videos.splice(j, 1);
          }
        }
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
