<template>
  <div class="page page--user-form">
    <page-title
      title="Add User"
      :showPlaylistButton="false"
      :showUploadButton="false"
    />
    <div class="container" v-if="entity">
      <div class="input-label">Full Name</div>
      <input type="text" v-model="entity.name" />

      <div class="input-label">Email</div>
      <input type="email" v-model="entity.email" />

      <div class="input-label">
        New Password - min. 8 characters<br/>
        <small>(leave it blank if you don't want it to change)</small>
      </div>
      <input type="text" v-model="entity.password" />

      <button class="button primary" @click="add()">
        <pulse-loader
          v-if="isLoading"
          :loading="isLoading"
          color="#ffffff"
          size="6px"
        />
        <template v-else>
          <span class="icon"><i class="fa fa-save" /></span> Save
        </template>
      </button>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
//import User from '@/entities/User.js';

export default {
  name: 'EditUser',
  components: {
    PageTitle,
    PulseLoader,
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    userId() {
      return this.$route.params.id;
    },
    entity() {
      return this.$store.getters['user/get'](this.userId);
    },
  },
  created() {
    this.$store.dispatch('user/get', this.userId);
  },
  mounted() {
    this.$nextTick(() => {
      document.querySelector('.sidebar a[href="/users"]').classList.add('router-link-exact-active', 'router-link-active')
    })
  },
  destroyed() {
    document.querySelector('.sidebar a[href="/users"]').classList.remove('router-link-exact-active', 'router-link-active');
  },
  methods: {
    add() {
      if (!this.isLoading) {
        this.isLoading = true;
        this.$store
          .dispatch('user/save', this.entity)
          .then(() => {
            this.$router.replace({ path: '/users' });
            this.$toast.success('User was updated');
          })
          .catch((error) => {
            this.$toast.error(error.message)
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
