var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page page--user-form" },
    [
      _c("page-title", {
        attrs: {
          title: "Add User",
          showPlaylistButton: false,
          showUploadButton: false,
        },
      }),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "input-label" }, [_vm._v("Full Name")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.entity.name,
              expression: "entity.name",
            },
          ],
          attrs: { type: "text" },
          domProps: { value: _vm.entity.name },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.entity, "name", $event.target.value)
            },
          },
        }),
        _c("div", { staticClass: "input-label" }, [_vm._v("Email")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.entity.email,
              expression: "entity.email",
            },
          ],
          attrs: { type: "email" },
          domProps: { value: _vm.entity.email },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.entity, "email", $event.target.value)
            },
          },
        }),
        _c("div", { staticClass: "input-label" }, [
          _vm._v("Password - min. 8 characters"),
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.entity.password,
              expression: "entity.password",
            },
          ],
          attrs: { type: "text" },
          domProps: { value: _vm.entity.password },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.entity, "password", $event.target.value)
            },
          },
        }),
        _c(
          "button",
          {
            staticClass: "button primary",
            on: {
              click: function ($event) {
                return _vm.add()
              },
            },
          },
          [
            _vm.isLoading
              ? _c("pulse-loader", {
                  attrs: {
                    loading: _vm.isLoading,
                    color: "#ffffff",
                    size: "6px",
                  },
                })
              : [
                  _c("span", { staticClass: "icon" }, [
                    _c("i", { staticClass: "fa fa-user-plus" }),
                  ]),
                  _vm._v(" Add User "),
                ],
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }