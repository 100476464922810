<template>
  <div class="on-off" :class="type">
    <label class="switch">
      <input
        type="checkbox"
        :checked="value"
        @change="$emit('change')"
        @input="input"
      />
      <span class="slider"></span>
    </label>
  </div>
</template>
<script>
export default {
  name: 'OnOff',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
    //change: call parent method eg: @change="function"
  },
  methods: {
    input(event) {
      this.$emit('input', event.target.checked);
    },
  },
};
</script>
