var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      !_vm.isLoggedIn
        ? [_c("div", { staticClass: "center-content" }, [_c("router-view")], 1)]
        : [
            _vm.isLoading
              ? _c("div", { staticClass: "loader" }, [_c("i")])
              : [
                  _c("app-header"),
                  _c(_vm.sidebarComponent, { tag: "component" }),
                  _c(
                    "div",
                    { staticClass: "main-content" },
                    [
                      _c("router-view"),
                      _c("video-upload-modal"),
                      _c("create-playlist-modal"),
                    ],
                    1
                  ),
                ],
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }