<template>
  <div class="app-header">

    <div class="navbar">
      <router-link to="/" class="logo">
        <i class="icon header-logo" />
      </router-link>

      <div class="profile" @click="openMenu()">
        <span v-if="user" class="user">{{ user.name }}</span>
        <i class="icon profile-avatar" />
        <i class="icon dropdown-arrow" />
      </div>
    </div>

    <div class="dropdown" :class="{ opened: isOpened }">
      <ul>
        <li @click="logOut">Log Out</li>
      </ul>
    </div>

  </div>
</template>

<script>
export default {
  name: 'AppHeader',
  data: () => ({
    isOpened: false,
  }),
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    openMenu() {
      this.isOpened = !this.isOpened;
    },
    logOut() {
      this.$store.dispatch('auth/logout');
    },
  },
};
</script>
