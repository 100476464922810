var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page page--stat" },
    [
      _c("page-title", { attrs: { title: "Statistics" } }),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "div",
            { staticClass: "calendar-container" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.statDateFrom,
                    expression: "statDateFrom",
                  },
                ],
                ref: "dateInput",
                staticClass: "date-input",
                attrs: { type: "text" },
                domProps: { value: _vm.statDateFrom },
                on: {
                  focus: _vm.onFocusDateInput,
                  blur: _vm.onBlurDateInput,
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.statDateFrom = $event.target.value
                  },
                },
              }),
              _c("p", { staticClass: "dash" }, [_vm._v("-")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.statDateTo,
                    expression: "statDateTo",
                  },
                ],
                ref: "dateInput",
                staticClass: "date-input",
                attrs: { type: "text" },
                domProps: { value: _vm.statDateTo },
                on: {
                  focus: _vm.onFocusDateInput,
                  blur: _vm.onBlurDateInput,
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.statDateTo = $event.target.value
                  },
                },
              }),
              _c("functional-calendar", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.calendarIsVisible,
                    expression: "calendarIsVisible",
                  },
                ],
                ref: "calendar",
                staticClass: "calendar",
                attrs: { configs: _vm.calendarConfigs },
                on: {
                  dayClicked: function ($event) {
                    return _vm.clearDateRange()
                  },
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.calendarStayInFocus()
                  },
                },
                model: {
                  value: _vm.calendarData,
                  callback: function ($$v) {
                    _vm.calendarData = $$v
                  },
                  expression: "calendarData",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "button primary",
                  on: {
                    click: function ($event) {
                      return _vm.getStat()
                    },
                  },
                },
                [_vm._v("Filter")]
              ),
            ],
            1
          ),
          _vm.isLoading
            ? [
                _vm.isLoading
                  ? _c(
                      "div",
                      {
                        staticClass: "loader",
                        staticStyle: { "margin-top": "50px" },
                      },
                      [_c("i")]
                    )
                  : _vm._e(),
              ]
            : [
                _c("ul", { staticClass: "tab-navigation" }, [
                  _c(
                    "li",
                    {
                      class: { active: _vm.activeTab === "general" },
                      on: {
                        click: function ($event) {
                          _vm.activeTab = "general"
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fad fa-chart-area" }),
                      _vm._v(" General "),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      class: { active: _vm.activeTab === "playlist" },
                      on: {
                        click: function ($event) {
                          _vm.activeTab = "playlist"
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fad fa-list" }),
                      _vm._v(" Playlist "),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      class: { active: _vm.activeTab === "video" },
                      on: {
                        click: function ($event) {
                          _vm.activeTab = "video"
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fad fa-photo-video" }),
                      _vm._v(" Video "),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeTab === "general",
                        expression: "activeTab === 'general'",
                      },
                    ],
                  },
                  [
                    _c("h4", [_vm._v("View History")]),
                    _c("p", [
                      _vm._v("Total Views: "),
                      _c("strong", [_vm._v(_vm._s(_vm.totalViews) + " views")]),
                    ]),
                    _c("line-chart", {
                      ref: "views-line",
                      attrs: {
                        id: "views-line",
                        data: _vm.viewsChartData,
                        xkey: "date",
                        ykeys: '[ "value" ]',
                        labels: '[ "Total Views" ]',
                        "line-colors": '[ "#fdd066" ]',
                        grid: "true",
                        "post-units": "  views",
                        "hide-hover": "auto",
                        "grid-text-family": "Open Sans",
                        "grid-text-weight": "600",
                        "grid-text-size": 13,
                        "grid-text-color": "#515252",
                        "behave-like-line": true,
                        "point-size": 4,
                        "line-width": 3,
                        resize: true,
                        "fill-ppacity": "0.6",
                      },
                    }),
                    _c("br"),
                    _c("h4", [_vm._v("Watch Time")]),
                    _c("p", [
                      _vm._v("Total Watch Time: "),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.totalWatchTimes) + " minutes"),
                      ]),
                    ]),
                    _c("line-chart", {
                      ref: "watch-time-line",
                      attrs: {
                        id: "watch-time-line",
                        data: _vm.watchTimesChartData,
                        xkey: "date",
                        ykeys: '[ "value" ]',
                        labels: '[ "Watch time" ]',
                        "line-colors": '[ "#fdd066" ]',
                        grid: "true",
                        "post-units": "  minutes",
                        "hide-hover": "auto",
                        "grid-text-family": "Open Sans",
                        "grid-text-weight": "600",
                        "grid-text-size": 13,
                        "grid-text-color": "#515252",
                        "behave-like-line": true,
                        "point-size": 4,
                        "line-width": 3,
                        resize: true,
                        "fill-ppacity": "0.6",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeTab === "playlist",
                        expression: "activeTab === 'playlist'",
                      },
                    ],
                  },
                  [
                    _c("table", [
                      _vm._m(0),
                      _c(
                        "tbody",
                        _vm._l(_vm.playlistStat, function (playlist) {
                          return _c(
                            "tr",
                            { key: `playlist-stat-${playlist.id}` },
                            [
                              _c(
                                "td",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to:
                                          "/library/" +
                                          _vm.libraryId +
                                          "/playlist/" +
                                          playlist.id,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(playlist.name) + " ")]
                                  ),
                                ],
                                1
                              ),
                              _c("td", [_vm._v(_vm._s(playlist.views))]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    Math.round(
                                      (playlist.watch_time / 60 / 60) * 100
                                    ) / 100
                                  ) + " hours"
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeTab === "video",
                        expression: "activeTab === 'video'",
                      },
                    ],
                  },
                  [
                    _c("div", { staticClass: "search" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchFor,
                            expression: "searchFor",
                          },
                        ],
                        attrs: { type: "text", placeholder: "Search..." },
                        domProps: { value: _vm.searchFor },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.searchFor = $event.target.value
                          },
                        },
                      }),
                      _vm._m(1),
                    ]),
                    _c("table", [
                      _vm._m(2),
                      _c(
                        "tbody",
                        _vm._l(_vm.filteredVideoStat, function (video) {
                          return _c(
                            "tr",
                            { key: `video-stat-${video.data.id}` },
                            [
                              _c("td", [_vm._v(_vm._s(video.data.name))]),
                              _c("td", [_vm._v(_vm._s(video.views))]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    Math.round((video.watch_time / 60) * 100) /
                                      100
                                  ) + " minutes"
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "60%" } }, [_vm._v("Name")]),
        _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Views")]),
        _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Watch Time")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "right" }, [
      _c("i", { staticClass: "fa fa-search fa-lg" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "60%" } }, [_vm._v("Name")]),
        _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Views")]),
        _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Watch Time")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }