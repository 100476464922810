<template>
  <div class="page page--manage-playlists">
    <page-title title="Manage Playlists" />
    <div class="container">
      <div class="wrapper">
        <playlist-item
          v-for="playlist in playlists"
          :key="playlist.id"
          :playlist="playlist"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import PlaylistItem from '@/components/PlaylistItem.vue';

export default {
  name: 'ManagePlaylists',
  components: {
    PageTitle,
    PlaylistItem
  },
  computed: {
    playlists() {
      return this.$store.getters['playlist/list'];
    },
  },
  methods: {
    onUpdateOrder(event) {
      console.log(event)
    },
  }
};
</script>
