<template>
  <div class="page page--advertising">
    <page-title title="Advertising" />
    <div class="container">
      <h2>VAST Tag Url:</h2>
        <p>
          Automatically display VAST based ads in your videos.
        </p>
        <p>
          <input-with-button
            v-model="library.vast_tag_url"
            buttonLabel="<i class='fa fa-save'></i> Save"
            @click="save"
          />
        </p>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import InputWithButton from '@/components/InputWithButton';

export default {
  name: 'Advertising',
  components: {
    PageTitle,
    InputWithButton
  },
  computed: {
    libraryId() {
      return this.$route.params.libraryId;
    },
    library() {
      return this.$store.getters['library/get'](this.libraryId)
    }
  },
  methods: {
    save() {
      this.$store.dispatch('library/save', this.library)
        .then(() => {
          this.$toast.success('URL was updated');
        })
        .catch(() =>
          this.$toast.error('Invalid URL!')
        );
    }
  },
};
</script>
