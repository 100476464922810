var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "playlist-item" },
    [
      _c(
        "router-link",
        {
          staticClass: "item-wrapper",
          attrs: {
            to: `/library/${_vm.libraryId}/playlist/${_vm.playlist.id}`,
          },
        },
        [
          _c("div", { staticClass: "icons" }, [
            _c("i", { staticClass: "fad fa-folders" }),
            _vm.videos
              ? _c(
                  "div",
                  { staticClass: "thumbnails" },
                  _vm._l(_vm.videos, function (video) {
                    return _c(
                      "div",
                      { key: `${_vm.playlist.id}-${video.id}` },
                      [_c("img", { attrs: { src: video.thumbnail_url } })]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "playlist-title" }, [
            _vm._v(" " + _vm._s(_vm.playlist.name) + " "),
          ]),
          _c("div", { staticClass: "number-of-videos" }, [
            _c("i", { staticClass: "fad fa-play" }),
            _vm._v(" " + _vm._s(_vm.videoNumber(_vm.playlist)) + " "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }