var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.classes, on: { click: _vm.onClick } }, [
    _vm.isBatchSelecting
      ? _c("div", { staticClass: "batch-operation-layer" }, [
          _c("span", { class: { checkbox: true, checked: _vm.isSelected } }, [
            _c("i"),
          ]),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "cover" },
      [
        _vm.video.thumbnail_url
          ? _c("v-lazy-image", { attrs: { src: _vm.video.thumbnail_url } })
          : _vm._e(),
        _vm.statusIsWaiting
          ? _c("div", { staticClass: "status-label" }, [
              _c("i", { staticClass: "fas fa-clock" }),
              _vm._v(" Waiting "),
            ])
          : _vm.statusIsInProgress
          ? _c("div", { staticClass: "status-label" }, [
              _c("i", { staticClass: "fas fa-cog fa-spin" }),
              _vm._v(" Processing "),
            ])
          : _vm.video.views
          ? _c("div", { staticClass: "views" }, [
              _vm._v(_vm._s(_vm.video.views) + " views"),
            ])
          : _vm._e(),
      ],
      1
    ),
    _c("div", { staticClass: "details" }, [
      _c("div", { staticClass: "left" }, [
        _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.video.name))]),
        _c(
          "div",
          { staticClass: "info" },
          [
            _vm.statusIsWaiting
              ? _c("span", [
                  _c("i", { staticClass: "fad fa-clock" }),
                  _vm._v(" Waiting for processing "),
                ])
              : _vm.statusIsInProgress
              ? _c("span", [
                  _c("i", { staticClass: "far fa-fw fa-waveform-path" }),
                  _vm._v(" Processing "),
                ])
              : [
                  _c("i", { staticClass: "fad fa-clock" }),
                  _vm._v(" " + _vm._s(_vm.length) + " "),
                  _c("i", { staticClass: "fa fa-video" }),
                  _vm._v(" " + _vm._s(_vm.video.max_resolution) + " "),
                ],
          ],
          2
        ),
      ]),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "right" }, [
      _c("div", { staticClass: "play-button" }, [
        _c("i", { staticClass: "fa fa-play" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }