<template>
  <div class="auth-page login-form-view">
    <logo />    
    <div class="content">
      <div class="title">Log in to your account</div>
      <form class="credentials" @submit.prevent="submit">
        <input
          v-model="email"
          type="email"
          class="big"
          placeholder="E-mail"
        />
        <input
          v-model="password"
          type="password"
          class="big"
          placeholder="Password"
        />
        <button type="submit" class="button primary big">
          <pulse-loader
            v-if="isLoading"
            :loading="isLoading"
            color="#ffffff"
            size="6px"
          />
          <template v-else>Sign in</template>
        </button>
        <div class="forgotten-password">
          <span>Forgot password?</span>
          <router-link to="/auth/forgotten-password">Click here!</router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Logo from '@/components/Logo.vue';

import { UnauthorizedError } from '@/errors';
import { isValidEmail } from '@/utils/validators';

export default {
  name: 'Login',
  components: {
    Logo,
    PulseLoader,
  },
  data() {
    return {
      isLoading: false,
      email: '',
      password: '',
    };
  },
  methods: {
    resetForm() {
      this.email = '';
      this.password = '';
    },
    submit() {
      if (!this.validate()) {
        return;
      }

      this.isLoading = true;
      this.$store
        .dispatch('auth/login', {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          this.$router.replace({ path: '/' });
          this.resetForm();
        })
        .catch((err) => {
          let message;
          if (err instanceof UnauthorizedError) {
            message = 'Érvénytelen email cím vagy jelszó';
          } else {
            message = err.message;
          }

          this.$toast.error(message);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    validate() {
      this.$toast.clear();

      if (!this.email) {
        this.$toast.error('Email mező üres');
        return false;
      }
      else if (!isValidEmail(this.email)) {
        this.$toast.error('A megadott email cím érvénytelen');
        return false;
      }
      else if (!this.password) {
        this.$toast.error('Jelszó mező üres');
        return false;
      }

      return true;
    },
  },
};
</script>
