<template>
  <div ref="colorpicker" :class="cssClasses">
    <input
      v-model="colorValue"
      type="text"
      :disabled="disabled"
      maxlength="7"
      @focus="showPicker"
      @input="updateFromInput"
    >
    <span
      class="current-color"
      :style="'background-color: ' + colorValue"
      @click="togglePicker"
    />
    <sketch-picker
      v-if="displayPicker"
      :value="colors"
      :disableAlpha="true"
      @input="updateFromPicker"
    />
  </div>
</template>

<script>
import { Sketch } from 'vue-color'

export default {
  name: 'ColorPickerInput',
  components: {
    SketchPicker: Sketch,
  },
  props: ['value', 'disabled'],
  data: () => ({
    colors: {
      hex: '#FFFFFF',
    },
    colorValue: '',
    displayPicker: false,
  }),
  computed: {
    cssClasses() {
      return {
        'color-picker-input-component': true,
        'disabled': this.disabled,
      };
    }
  },
  mounted() {
    this.colorValue = this.value;
  },
  methods: {
    updateColors(color) {
      if (!color) {
        return null;
      }

      if (color.slice(0, 1) === '#') {
        this.colors = {
          hex: color
        };
      }
      else if (color.slice(0, 4) === 'rgba') {
        const rgba = color.replace(/^rgba?\(|\s+|\)$/g,'').split(',');
        const hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1);
        this.colors = {
          hex: hex,
          a: rgba[3],
        }
      }
    },
    showPicker() {
      if (this.disabled) {
        return;
      }

      document.addEventListener('click', this.documentClick);
      this.displayPicker = true;
    },
    hidePicker() {
      document.removeEventListener('click', this.documentClick);
      this.displayPicker = false;
    },
    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker();
    },
    updateFromInput() {
      this.updateColors(this.value);
    },
    updateFromPicker(color) {
      this.colors = color;

      if (color.rgba.a == 1) {
        this.colorValue = color.hex;
      }
      else {
        this.colorValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
      }
    },
    documentClick(e) {
      const el = this.$refs.colorpicker;
      const target = e.target;

      if (el !== target && !el.contains(target)) {
        this.hidePicker()
      }
    }
  },
  watch: {
    value() {
      this.colorValue = this.value;
    },
    colorValue() {
      this.updateColors(this.colorValue);
      this.$emit('input', this.colorValue);
    },
  },
}
</script>
