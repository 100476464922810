<template>
  <modal
    name="rename-library"
    class="rename-library-modal"
    @before-open="libraryName = library.name"
  >
    <div class="upper">
      <h2>Rename library</h2>
      <input
        type="text"
        placeholder="Write a library name..."
        v-model="libraryName"
        @keyup.enter="renameLibrary"
      />
    </div>
    <div class="buttons">
      <button class="button primary" @click="renameLibrary">Rename</button>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'RenameLibraryModal',
  data: () => ({
    libraryName: '',
  }),
  computed: {
    libraryId() {
      return this.$route.params.libraryId;
    },
    library() {
      return this.$store.getters['library/get'](this.libraryId);
    },
  },
  methods: {
    hideModal() {
      this.$modal.hide('rename-library');
    },
    renameLibrary() {
      if (!this.libraryName) {
        this.$toast.error('Invalid library name!')
      } else {
        this.library.name = this.libraryName;
        this.$store.dispatch('library/save', this.library)
          .then(() => {
            this.$toast.success('Renamed library!');
            this.hideModal();
          });
      }
    },
  },
};
</script>
