<template>
  <div
    :class="classes"
    :data-video-id="video.id"
    @click="onClick"
  >
    <div class="cover">
        <img v-show="video.thumbnail_url" :src="video.thumbnail_url">
        <div v-if="video.views" class="views">{{ video.views }} views</div>
    </div>
    <div class="details">
      <div class="left">
        <div class="name">{{ video.name }}</div>
        <div class="info">
          <template>
            <i class="fad fa-clock"></i> {{ length }}
            <i class="fa fa-video"></i> {{ video.max_resolution }}
          </template>
        </div>
      </div>
      <div class="right">
        <div class="remove-video" @click.stop="removeVideo">
          <i class="fa fa-times"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Video from '@/entities/Video';
import VideoMixin from '@/mixins/video';

export default {
  name: 'VideoItem',
  mixins: [ VideoMixin ],
  props: {
    video: {
      type: Video,
      required: true,
    },
    playlist: {
      type: Object,
      required: true,
    },
  },
  computed: {
    classes() {
      return {
        'video-item': true,
      }
    },
  },
  methods: {
    onClick() {
      this.$emit('click', this.video.id);
    },
    removeVideo() {
      this.$store.dispatch('playlist/deleteVideoFromPlaylist',
        { playlist: this.playlist, video: this.video })
          .then(() => {
            this.$toast.success('Removed video from playlist!', { duration: 2000 });
          })
          .catch(() => {
            this.$toast.error('Error! Could not remove video!', { duration: 2000 });
          });
    }
  }
}
</script>
