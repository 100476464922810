import Video from '@/entities/Video';

export default class Library {

  id = null;
  name = '';
  video_count = 0;
  player_style = {
    'color-main': null,
  };
  player_show_title = true;
  player_logo_url = '';
  player_show_logo = true;
  vast_tag_url = '';
  gemius_id = '';
  allowed_domains = [];

  videos = [];
  stat = null;

  constructor (rawData) {
    if (Object.prototype.toString.call(rawData) !== '[object Object]') {
      return;
    }

    this.updateWith(rawData);
  }

  get isNew() {
    return this.id === null;
  }

  updateWith(newValues) {
    for (const [key, value] of Object.entries(newValues)) {
      if (typeof this[key] === 'undefined') {
        continue;
      }

      if (key === 'player_style') {
        for (let styleKey in value) {
          this[key][styleKey] = value[styleKey];
        }
      }
      else if (key === 'videos') {
        if (value.length) {
          this.videos = [];
        }
        for (let item of value) {
          const entity = new Video(item);
          this[key].push(entity);
        }
      }
      else {
        this[key] = value;
      }
    }
  }

}
