var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "on-off", class: _vm.type }, [
    _c("label", { staticClass: "switch" }, [
      _c("input", {
        attrs: { type: "checkbox" },
        domProps: { checked: _vm.value },
        on: {
          change: function ($event) {
            return _vm.$emit("change")
          },
          input: _vm.input,
        },
      }),
      _c("span", { staticClass: "slider" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }