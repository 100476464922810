var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page page--user-list" },
    [
      _c("page-title", {
        attrs: {
          title: "Manage Users",
          showPlaylistButton: false,
          showUploadButton: false,
        },
      }),
      _c(
        "router-link",
        { staticClass: "button primary add-user", attrs: { to: "user/add" } },
        [
          _c("span", { staticClass: "icon" }, [
            _c("i", { staticClass: "fa fa-user-plus" }),
          ]),
          _vm._v(" Add user "),
        ]
      ),
      _vm.users.length
        ? _c("div", { staticClass: "container" }, [
            _c("table", [
              _vm._m(0),
              _c(
                "tbody",
                _vm._l(_vm.users, function (user) {
                  return _c("tr", { key: user.id }, [
                    _c(
                      "td",
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/user/" + user.id } },
                          [
                            _c("i", { staticClass: "fa fa-user fa-lg" }),
                            _vm._v(" " + _vm._s(user.name) + " "),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("td", [_vm._v(_vm._s(user.email))]),
                    _c(
                      "td",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "button secondary",
                            attrs: { to: "/user/" + user.id },
                          },
                          [
                            _c("span", { staticClass: "icon" }, [
                              _c("i", { staticClass: "fa fa-edit" }),
                            ]),
                            _vm._v(" Edit "),
                          ]
                        ),
                        !_vm.userIsSuperadmin(user)
                          ? _c(
                              "button",
                              {
                                staticClass: "button secondary",
                                on: {
                                  click: function ($event) {
                                    return _vm.confirmDelete(user)
                                  },
                                },
                              },
                              [
                                _vm.isDeleting
                                  ? _c("pulse-loader", {
                                      attrs: {
                                        loading: _vm.isDeleting,
                                        color: "#ffffff",
                                        size: "6px",
                                      },
                                    })
                                  : [
                                      _c("span", { staticClass: "icon" }, [
                                        _c("i", { staticClass: "fa fa-times" }),
                                      ]),
                                      _vm._v(" Remove "),
                                    ],
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Name")]),
        _c("th", [_vm._v("Email")]),
        _c("th", { staticStyle: { width: "260px" } }, [_vm._v("Manage")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }