var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page page--delete-library" },
    [
      _c("page-title", { attrs: { title: "Delete Library" } }),
      _c("div", { staticClass: "container" }, [
        _c("h4", [_vm._v("Delete Video Library?")]),
        _c("p", { staticClass: "help-paragraph" }, [
          _vm._v(
            " Deleting the library will immediately remove all uploaded videos videos. This action cannot be undone. "
          ),
        ]),
        _c(
          "button",
          {
            staticClass: "button",
            on: {
              click: function ($event) {
                return _vm.showDeleteConfirmLibrary()
              },
            },
          },
          [
            _c("i", { staticClass: "fa fa-trash" }),
            _vm._v("Delete Video Library "),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }