export default {
  computed: {
    statusIsInProgress() {
      return this.video.status === this.$videoStatuses.in_progress;
    },
    statusIsWaiting() {
      return this.video.status === this.$videoStatuses.waiting;
    },
    length() {
      let sec = this.video.length;
      const hour = Math.floor(sec / 3600);
      sec -= hour * 3600;

      const min = Math.floor(sec / 60);
      sec -= min * 60;

      const hourStr = (hour < 10 ? '0' : '') + hour.toString();
      const minStr = (min < 10 ? '0' : '') + min.toString();
      const secStr = (sec < 10 ? '0' : '') + sec.toString();

      return hourStr + ':' + minStr + ':' + secStr;
    },
  },
}
