<template>
  <div class="sidebar">
    <div class="title">
      <button @click="showLibraryRenameModal" class="button icon-only clean">
        <i class="fad fa-pencil"></i>
      </button>
      {{ library.name }}
    </div>
    <rename-library-modal />

    <div class="functions">
      <router-link :to="`/library/${library.id}`">
        <i class="fa fa-fw fa-photo-video" />
        Manage Videos
      </router-link>
      <router-link :to="`/library/${library.id}/playlists`">
        <i class="far fa-fw fa-list" />
        Manage Playlists
      </router-link>
      <router-link :to="`/library/${library.id}/player`">
        <i class="far fa-fw fa-play" />
        Player
      </router-link>
      <router-link :to="`/library/${library.id}/stat`">
        <i class="far fa-fw fa-chart-area" />
        Statistics
      </router-link>
      <router-link :to="`/library/${library.id}/advertising`">
        <i class="far fa-fw fa-ad" />
        Advertising
      </router-link>
      <router-link :to="`/library/${library.id}/security`">
        <i class="far fa-fw fa-shield-alt" />
        Security
      </router-link>
      <router-link :to="`/library/${library.id}/gemius`">
        <i class="far fa-network-wired" />
        Gemius
      </router-link>
      <router-link :to="`/library/${library.id}/delete`">
        <i class="far fa-fw fa-trash-alt" />
        Delete Library
      </router-link>
    </div>
  </div>
</template>

<script>
import RenameLibraryModal from '@/components/RenameLibraryModal.vue';

export default {
  name: 'Sidebar',
  components: {
    RenameLibraryModal,
  },
  computed: {
    libraryId() {
      return this.$route.params.libraryId;
    },
    library() {
      return this.$store.getters['library/get'](this.libraryId);
    },
  },
  methods: {
    showLibraryRenameModal() {
      this.$modal.show('rename-library');
    },

  },
};
</script>
