var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "auth-page forgotten-password-view" },
    [
      _c("logo"),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Reset your password")]),
        _c(
          "div",
          { staticClass: "credentials" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email",
                },
              ],
              staticClass: "big",
              attrs: { type: "email", placeholder: "example@email.com" },
              domProps: { value: _vm.email },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.email = $event.target.value
                },
              },
            }),
            _c(
              "button",
              { staticClass: "button primary big", on: { click: _vm.submit } },
              [
                _vm.isLoading
                  ? _c("pulse-loader", {
                      attrs: {
                        loading: _vm.isLoading,
                        color: "#ffffff",
                        size: "6px",
                      },
                    })
                  : [_vm._v("Reset password")],
              ],
              2
            ),
            _c(
              "router-link",
              { staticClass: "back", attrs: { to: "/auth/login" } },
              [_vm._v(" Back to login ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }