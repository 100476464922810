<template>
  <div class="page-title">
    <h3>{{ title }}</h3>
    <button
      v-if="showPlaylistButton"
      class="button secondary"
      @click="showPlaylistModal"
    >
      <span> <i class="fa fa-fw fa-plus" /> Create Playlist </span>
    </button>
    <button v-if="showUploadButton" class="button" @click="showUploadModal">
      <div
        v-if="isUploading"
        class="progress"
        :style="{ width: uploadProgress + '%' }"
      ></div>
      <span> <i :class="uploadVideoIconClasses" /> Upload Videos </span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
    showUploadButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    showPlaylistButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    isUploading() {
      return this.$store.getters['upload/isUploading'];
    },
    uploadProgress() {
      return this.$store.getters['upload/uploadProgress'];
    },
    uploadVideoIconClasses() {
      return {
        'fa fa-fw fa-plus': !this.isUploading,
        'fas fa-sync fa-spin': this.isUploading,
      };
    },
  },
  methods: {
    showUploadModal() {
      this.$modal.show('upload-video');
    },
    showPlaylistModal() {
      this.$modal.show('create-playlist');
    },
  },
};
</script>
