<template>
  <div class="page page--library-list">
    <page-title
      title="Video Libraries"
      :showPlaylistButton="false"
      :showUploadButton="false"
    />
    <div class="search">
      <input v-model="searchFor" type="text" placeholder="Search..." />
      <span class="right"> <i class="fa fa-search fa-lg" /></span>
    </div>

    <div class="container">
      <table>
        <thead>
          <tr>
            <th style="width: 70%;">Name</th>
            <th style="width: 30%;">Video Count</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="library in filteredList" :key="library.id">
            <td>
              <router-link :to="'/library/' + library.id">
                <i class="fa fa-play fa-lg" /> {{ library.name }}
              </router-link>
            </td>
            <td>{{ library.video_count }}</td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';

export default {
  name: 'LibraryList',
  components: {
    PageTitle,
  },
  data: () => ({
    searchFor: '',
  }),
  computed: {
    libraries() {
      return this.$store.getters['library/list'];
    },
    filteredList() {
      if (!this.searchFor) {
        return this.libraries;
      }
      const term = this.searchFor.toLowerCase();

      return this.libraries.filter(item => item.name.toLowerCase().includes(term));
    },
  },
};
</script>
