import Video from '@/entities/Video';

export default class Playlist {

  id = null;
  name = '';
  vast_tag_url = '';
  disable_ads = false;

  videos = [];

  constructor (rawData) {
    if (Object.prototype.toString.call(rawData) !== '[object Object]') {
      return;
    }

    this.updateWith(rawData);
  }

  get isNew() {
    return this.id === null;
  }

  updateWith(newValues) {
    for (const [key, value] of Object.entries(newValues)) {
      if (typeof this[key] === 'undefined') {
        continue;
      }

      if (key === 'videos') {
        this.videos = [];
        for (let item of value) {
          const entity = new Video(item);
          this[key].push(entity);
        }
      }
      else {
        this[key] = value;
      }
    }
  }

}
