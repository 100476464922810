var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page page--playlist" },
    [
      _c("page-title", { attrs: { title: "Manage Playlist" } }),
      _vm.playlist
        ? _c(
            "div",
            { staticClass: "container" },
            [
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "button clean delete",
                    on: {
                      click: function ($event) {
                        return _vm.showDeleteConfirmPlaylist()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-trash" }), _vm._v(" Delete ")]
                ),
              ]),
              _c("div", { staticClass: "title" }, [
                _c(
                  "button",
                  {
                    staticClass: "button icon-only clean",
                    on: { click: _vm.showPlaylistRenameModal },
                  },
                  [_c("i", { staticClass: "fad fa-pencil" })]
                ),
                _vm._v(" " + _vm._s(_vm.playlist.name) + " "),
                _c("span", { class: _vm.spanClass }, [
                  _c("i", { staticClass: "fad fa-play" }),
                  _vm._v(" " + _vm._s(_vm.videoNumber) + " "),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "item-list-container" },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isLoading,
                          expression: "isLoading",
                        },
                      ],
                      staticClass: "loader",
                    },
                    [_c("i")]
                  ),
                  _c(
                    "draggable",
                    {
                      staticClass: "item-list",
                      attrs: {
                        "force-fallback": true,
                        "fallback-tolerance": 50,
                      },
                      on: { end: _vm.updateVideoOrder },
                    },
                    _vm._l(_vm.videos, function (video) {
                      return _c("playlist-video-item", {
                        key: video.id,
                        attrs: { video: video, playlist: _vm.playlist },
                        on: { click: _vm.openVideoModal },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.videos.length
                ? [
                    _c("ul", { staticClass: "tab-navigation" }, [
                      _c(
                        "li",
                        {
                          class: { active: _vm.activeTab === "embed" },
                          on: {
                            click: function ($event) {
                              _vm.activeTab = "embed"
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fad fa-code" }),
                          _vm._v(" Embed "),
                        ]
                      ),
                      _c(
                        "li",
                        {
                          class: { active: _vm.activeTab === "advertising" },
                          on: {
                            click: function ($event) {
                              _vm.activeTab = "advertising"
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fad fa-ad" }),
                          _vm._v(" Advertising "),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeTab === "embed",
                            expression: "activeTab === 'embed'",
                          },
                        ],
                        staticClass: "embed-tab",
                      },
                      [
                        _c("div", { staticClass: "video-preview" }, [
                          _c("div", {
                            attrs: { id: `summit-player--${_vm.playlistId}` },
                          }),
                        ]),
                        _c("div", { staticClass: "embed-code" }, [
                          _c("p", [
                            _vm._v(
                              "Use this code inside of your website HTML to display the player."
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "switches" },
                            [
                              _c("on-off", {
                                on: { change: _vm.checkAutoplay },
                                model: {
                                  value: _vm.autoplay,
                                  callback: function ($$v) {
                                    _vm.autoplay = $$v
                                  },
                                  expression: "autoplay",
                                },
                              }),
                              _vm._v(" Autoplay "),
                              _c("on-off", {
                                on: { change: _vm.checkMuted },
                                model: {
                                  value: _vm.muted,
                                  callback: function ($$v) {
                                    _vm.muted = $$v
                                  },
                                  expression: "muted",
                                },
                              }),
                              _vm._v(" Muted "),
                            ],
                            1
                          ),
                          _c("textarea", {
                            attrs: { readonly: "" },
                            domProps: { innerHTML: _vm._s(_vm.embedCode) },
                            on: {
                              click: function ($event) {
                                return _vm.copyToClipboard(_vm.embedCode)
                              },
                            },
                          }),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeTab === "advertising",
                            expression: "activeTab === 'advertising'",
                          },
                        ],
                        staticClass: "advertising-tab",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "disable-ads-container" },
                          [
                            _c("on-off", {
                              model: {
                                value: _vm.playlist.disable_ads,
                                callback: function ($$v) {
                                  _vm.$set(_vm.playlist, "disable_ads", $$v)
                                },
                                expression: "playlist.disable_ads",
                              },
                            }),
                            _c("p", [_vm._v("Disable ads")]),
                          ],
                          1
                        ),
                        _c("p", [
                          _vm._v(
                            "Here you can enter a unique VAST Tag URL for the playlist"
                          ),
                        ]),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.playlist.vast_tag_url,
                              expression: "playlist.vast_tag_url",
                            },
                          ],
                          domProps: { value: _vm.playlist.vast_tag_url },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.playlist,
                                "vast_tag_url",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "button primary",
                            on: { click: _vm.save },
                          },
                          [_vm._m(0), _vm._v(" Save ")]
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c("video-modal", { attrs: { "video-id": _vm.videoId } }),
      _c("rename-playlist-modal"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fa fa-save" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }