export default {
  computed: {
    currentUser() {
      return this.$store.getters['auth/user'];
    },
    currentUserIsSuperadmin() {
      return this.userIsSuperadmin(this.currentUser);
    }
  },
  methods: {
    /**
     * @param {User} user
     * 
     * @return {Boolean}
     */
    userIsSuperadmin(user) {
      if (!user) {
        return false;
      }

      return this.userHasRole(user, 'superadmin');
    },
    /**
     * @param {User} user
     * @param {String} roleMachineName
     * 
     * @return {Boolean}
     */
    userHasRole(user, roleMachineName) {
      if (!user || !user.roles) {
        return false;
      }
      
      return user.roles.find(role => role.machine_name === roleMachineName) ? true : false;
    },
  },
}
