var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.video
    ? _c(
        "div",
        { staticClass: "page" },
        [
          _c("router-link", { attrs: { to: `/video/${_vm.video.id}` } }, [
            _c("button", [_vm._v("Back")]),
          ]),
          _c("hr"),
          _vm._v(" Video "),
          _c("pre", [_vm._v(_vm._s(_vm.video))]),
          _c("hr"),
          _vm._v(" Name "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.video.name,
                expression: "video.name",
              },
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.video.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.video, "name", $event.target.value)
              },
            },
          }),
          _vm._v(" Vast Tag URL: "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.video.vast_tag_url,
                expression: "video.vast_tag_url",
              },
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.video.vast_tag_url },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.video, "vast_tag_url", $event.target.value)
              },
            },
          }),
          _c("button", { on: { click: _vm.save } }, [_vm._v("Mentés")]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }