<template>
  <div :class="classes" @click="onClick">

    <div v-if="isBatchSelecting" class="batch-operation-layer">
      <span :class="{'checkbox': true, 'checked': isSelected }"> <i /> </span>
    </div>

    <div class="cover">
      <v-lazy-image v-if="video.thumbnail_url" :src="video.thumbnail_url" />

      <div v-if="statusIsWaiting" class="status-label">
        <i class="fas fa-clock"></i> Waiting
      </div>

      <div v-else-if="statusIsInProgress" class="status-label">
        <i class="fas fa-cog fa-spin"></i> Processing
      </div>

      <div v-else-if="video.views" class="views">{{ video.views }} views</div>
    </div>

    <div class="details">
      <div class="left">
        <div class="name">{{ video.name }}</div>
        <div class="info">
          <span v-if="statusIsWaiting">
            <i class="fad fa-clock"></i> Waiting for processing
          </span>
          <span v-else-if="statusIsInProgress">
            <i class="far fa-fw fa-waveform-path"></i> Processing
          </span>
          <template v-else>
            <i class="fad fa-clock"></i> {{ length }}
            <i class="fa fa-video"></i> {{ video.max_resolution }}
          </template>
        </div>
      </div>
      <div class="right">
        <div class="play-button"> <i class="fa fa-play"></i> </div>
      </div>
    </div>

  </div>
</template>

<script>
import Video from '@/entities/Video';
import VideoMixin from '@/mixins/video';
import VLazyImage from "v-lazy-image/v2";
export default {
  name: 'VideoItem',
  mixins: [VideoMixin],
  components: {
    VLazyImage
  },
  props: {
    video: {
      type: Video,
      required: true,
    },
    isBatchSelecting: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    isSelected: false,
  }),
  computed: {
    classes() {
      return {
        'video-item': true,
        'waiting': this.statusIsWaiting,
        //'in-progress': this.statusIsInProgress,
      }
    },
  },
  methods: {
    onClick() {
      if (this.isBatchSelecting) {
        this.onSelect();
      } else if (this.statusIsInProgress) {
        this.$emit('click', this.video.id, true);
      } else {
        this.$emit('click', this.video.id);
      }

    },
    onSelect() {
      this.isSelected = !this.isSelected
      this.$emit('select', this.video.id);
    }
  }
}
</script>
