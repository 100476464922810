<template>
  <div class="page page--player">
    <page-title title="Player" />
    <div class="container">
      <h2>Main color:</h2>
      <p>
        Select the primary color that will be displayed for the controls in the video player
      </p>
      <p>
        <color-picker-input v-model="library.player_style['color-main']" />
      </p>

      <hr class="separator">
      
      <h2>
        <on-off v-model="library.player_show_title" type="big" />
        Show video title
      </h2>

      <hr class="separator">

      <button class="button" @click="onClickFileUpload">
        <span> <i class="fa fa-fw fa-plus" /> Upload logo </span>
      </button>
      <input
        type="file"
        ref="player_logo"
        hidden
        @change="previewFile"
        accept="image/*"
      />

      <div
        v-if="playerLogoUrl"
        class="player_logo"
        :style="playerLogoStyle"
      />

      <h2>
        <on-off v-model="library.player_show_logo" type="big" />
        Show logo
      </h2>
      
      <p>
        <button class="button primary" @click="save">
          <span class="icon">
            <i class='fa fa-save'></i>
          </span>
          Save
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import ColorPickerInput from '@/components/ColorPickerInput.vue';
import OnOff from '@/components/OnOff.vue';
import PageTitle from '@/components/PageTitle.vue';

export default {
  name: 'Player',
  components: {
    ColorPickerInput,
    OnOff,
    PageTitle,
  },
  data: () => ({
    logoFile: null,
    logoPreviewSrc: null,
  }),
  computed: {
    libraryId() {
      return this.$route.params.libraryId;
    },
    library() {
      return this.$store.getters['library/get'](this.libraryId)
    },
    playerLogoUrl() {
      return this.library.player_logo_url
        ? this.library.player_logo_url
        : this.logoPreviewSrc ? this.logoPreviewSrc : null;
    },
    playerLogoStyle() {
      const styles = {};

      if (this.playerLogoUrl) {
        styles['background-image'] = 'url(' + this.playerLogoUrl + ')';
      }

      return styles;
    },
  },
  methods: {
    onClickFileUpload() {
      this.$refs['player_logo'].click();
    },
    previewFile(event) {
      this.logoFile = event.target.files[0];
      this.logoPreviewSrc = URL.createObjectURL(this.logoFile);
    },
    save() {
      if (this.logoFile) {
        this.library.player_logo_file = this.logoFile;
      }

      this.$store.dispatch('library/save', this.library);
      this.$toast.clear();
      this.$toast.success('Player setting was updated');
    }
  },
};
</script>
