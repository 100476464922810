var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-title" }, [
    _c("h3", [_vm._v(_vm._s(_vm.title))]),
    _vm.showPlaylistButton
      ? _c(
          "button",
          {
            staticClass: "button secondary",
            on: { click: _vm.showPlaylistModal },
          },
          [_vm._m(0)]
        )
      : _vm._e(),
    _vm.showUploadButton
      ? _c(
          "button",
          { staticClass: "button", on: { click: _vm.showUploadModal } },
          [
            _vm.isUploading
              ? _c("div", {
                  staticClass: "progress",
                  style: { width: _vm.uploadProgress + "%" },
                })
              : _vm._e(),
            _c("span", [
              _c("i", { class: _vm.uploadVideoIconClasses }),
              _vm._v(" Upload Videos "),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fa fa-fw fa-plus" }),
      _vm._v(" Create Playlist "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }