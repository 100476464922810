var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "auth-page reset-password-view" },
    [
      _c("logo"),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Jelszó helyreállítása")]),
        _c("div", { staticClass: "credentials" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email",
              },
            ],
            staticClass: "big",
            attrs: {
              type: "email",
              placeholder: "E-mail cím megadása",
              readonly: "",
            },
            domProps: { value: _vm.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.email = $event.target.value
              },
            },
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password",
              },
            ],
            staticClass: "big",
            attrs: { type: "password", placeholder: "Jelszó" },
            domProps: { value: _vm.password },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.password = $event.target.value
              },
            },
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.passwordAgain,
                expression: "passwordAgain",
              },
            ],
            staticClass: "big",
            attrs: { type: "password", placeholder: "Jelszó megerősítése" },
            domProps: { value: _vm.passwordAgain },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.passwordAgain = $event.target.value
              },
            },
          }),
          _c(
            "button",
            { staticClass: "button big", on: { click: _vm.submit } },
            [
              _vm.isLoading
                ? _c("pulse-loader", {
                    attrs: {
                      loading: _vm.isLoading,
                      color: "#ffffff",
                      size: "6px",
                    },
                  })
                : [_vm._v("Jelszó beállítása")],
            ],
            2
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }