var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page page--security" },
    [
      _c("page-title", { attrs: { title: "Security" } }),
      _c("div", { staticClass: "container" }, [
        _c("h2", [_vm._v("Allowed domains")]),
        _c("p", [
          _vm._v(
            " The list of domains that are allowed to access the video. If no hostnames are listed, all requests will be allowed. "
          ),
        ]),
        _c(
          "p",
          [
            _c("input-with-button", {
              attrs: {
                buttonLabel: "<i class='fa fa-plus'></i> Add",
                inputPlaceholder: "Example: mywebsite.com",
              },
              on: { click: _vm.addDomain },
              model: {
                value: _vm.insertedValue,
                callback: function ($$v) {
                  _vm.insertedValue = $$v
                },
                expression: "insertedValue",
              },
            }),
          ],
          1
        ),
        _c(
          "ul",
          { staticClass: "domain-list" },
          _vm._l(_vm.domains, function (domain) {
            return _c("li", { key: domain }, [
              _vm._v(" " + _vm._s(domain) + " "),
              _c("i", {
                staticClass: "fa fa-times",
                on: {
                  click: function ($event) {
                    return _vm.removeDomain(domain)
                  },
                },
              }),
            ])
          }),
          0
        ),
        _c("p", [
          _c(
            "button",
            { staticClass: "button primary", on: { click: _vm.save } },
            [_vm._m(0), _vm._v(" Save ")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fa fa-save" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }