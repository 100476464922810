var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page page--manage-videos" },
    [
      _c("page-title", { attrs: { title: "Manage Videos" } }),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "actions" }, [
          _c(
            "div",
            { staticClass: "batch-operations" },
            [
              _c("v-select", {
                attrs: {
                  options: _vm.batchOperationOptions,
                  clearable: false,
                  searchable: false,
                },
                scopedSlots: _vm._u([
                  {
                    key: "open-indicator",
                    fn: function ({ attributes }) {
                      return [
                        _c(
                          "i",
                          _vm._b(
                            { staticClass: "icon dropdown-arrow" },
                            "i",
                            attributes,
                            false
                          )
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.batchOperation,
                  callback: function ($$v) {
                    _vm.batchOperation = $$v
                  },
                  expression: "batchOperation",
                },
              }),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isBatchSelecting,
                      expression: "isBatchSelecting",
                    },
                  ],
                  staticClass: "button",
                  class: {
                    disabled: !_vm.selectedVideoIds.length,
                    disabled: _vm.isLoading,
                  },
                  on: { click: _vm.doBatchOperation },
                },
                [_vm._v(" Apply ")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "search" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchFor,
                  expression: "searchFor",
                },
              ],
              attrs: { type: "text", placeholder: "Search..." },
              domProps: { value: _vm.searchFor },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.searchFor = $event.target.value
                },
              },
            }),
            _vm._m(0),
          ]),
        ]),
        _c("div", { staticClass: "item-list-container" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              staticClass: "loader",
            },
            [_c("i")]
          ),
          _c(
            "div",
            { staticClass: "item-list" },
            _vm._l(_vm.filteredVideos, function (video) {
              return _c("video-item", {
                key: video.id,
                attrs: {
                  video: video,
                  "is-batch-selecting": _vm.isBatchSelecting,
                },
                on: { select: _vm.selectVideo, click: _vm.openVideoModal },
              })
            }),
            1
          ),
        ]),
      ]),
      _c("video-modal", {
        attrs: {
          "video-id": _vm.videoId,
          "is-processing": this.isProcessingVideo,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "right" }, [
      _c("i", { staticClass: "fa fa-search fa-lg" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }