var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page page--manage-playlists" },
    [
      _c("page-title", { attrs: { title: "Manage Playlists" } }),
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "wrapper" },
          _vm._l(_vm.playlists, function (playlist) {
            return _c("playlist-item", {
              key: playlist.id,
              attrs: { playlist: playlist },
            })
          }),
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }