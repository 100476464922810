import Api from '@/services/Api';

export default {

  get(id) {
    return Api()
      .get('/playlist/' + id)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  /**
   * @param {string} libraryId 
   * @param {Playlist} entity 
   */
  create(libraryId, entity) {
    const data = {
      library_id: libraryId,
      name: entity.name,
    };

    return Api()
      .post('/playlist', data)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  update(entity) {
    return Api()
      .put('/playlist/' + entity.id, JSON.stringify(entity))
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }

        return resp;
      });
  },

  delete(entity) {
    return Api()
      .delete('/playlist/' + entity.id)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  /**
   * @param {string} playlistId 
   * @param {string} videoId 
   */
   addVideo(playlistId, videoId) {
    return Api()
      .post('/playlist/' + playlistId + '/video/' + videoId)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  /**
   * @param {string} playlistId 
   * @param {string} videoId 
   */
   deleteVideo(playlistId, videoId) {
    return Api()
      .delete('/playlist/' + playlistId + '/video/' + videoId)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  /**
   * @param {string} playlistId 
   * @param {string} videoId 
   * @param {integer} order
   */
  setVideoOrder(playlistId, videoId, order) {
    return Api()
      .put('/playlist/' + playlistId + '/video/' + videoId, { order })
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
};
