<template>
  <div class="auth-page signup-form-view">
    <logo />
    <div class="content">
      <div class="title">Regisztráció</div>
      <div v-if="isLoading" class="loader">
        <i />
      </div>
      <div v-else-if="invalidLink">
        Érvénytelen meghívó link.
      </div>
      <div v-else class="credentials">
        <input
          v-model="email"
          type="email"
          class="big"
          placeholder="E-mail cím megadása"
          readonly
        />
        <input
          v-model="name"
          type="text"
          class="big"
          placeholder="Név megadása"
          readonly
        />
        <input
          type="password"
          class="big"
          placeholder="Jelszó"
          v-model="password"
        />
        <input
          type="password"
          class="big"
          placeholder="Jelszó megerősítése"
          v-model="passwordAgain"
        />
        <div class="text">
          A regisztrációval elfogadom a szolgáltatási feltételeit, és tudomásul
          veszem az Adatvédelmi irányelveket.
        </div>
        <button class="button big" @click="submit">
          <pulse-loader
            v-if="isSaving"
            :loading="isSaving"
            color="#ffffff"
            size="6px"
          />
          <template v-else>Regisztrálok</template>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

import AuthService from '@/services/Auth';

export default {
  name: 'Signup',
  components: {
    Logo,
    PulseLoader,
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      name: '',
      email: '',
      password: '',
      passwordAgain: '',
    };
  },
  computed: {
    invitationHash() {
      return this.$route.params.hash;
    },
    invalidLink() {
      return !this.name || !this.email;
    },
  },
  created() {
    this.getInivitationDetails();
  },
  methods: {
    getInivitationDetails() {
      this.isLoading = true;

      AuthService.invitationValidate(this.invitationHash)
        .then(resp => {
          this.name = resp.name;
          this.email = resp.email;
        })
        .finally(() => this.isLoading = false);
    },
    submit() {
      if (this.isLoading || !this.validate()) {
        return;
      }

      this.isSaving = true;

      this.$store.dispatch('auth/signup', { hash: this.invitationHash, password: this.password })
        .then(() => {
          this.$router.replace({ path: '/' });
        })
        .catch((err) => {
          this.$toast.error(err.message);
        })
        .finally(() => this.isSaving = false);
    },
    validate() {
      this.$toast.clear();
      let errorMessage = null;

      if (!this.password) {
        errorMessage = 'Jelszó mező üres';
      }
      else if (!this.passwordAgain) {
        errorMessage = 'Jelszó megerősítése mező üres';
      }
      else if (this.password !== this.passwordAgain) {
        errorMessage = 'A jelszavak nem egyeznek!';
      }

      if (errorMessage !== null) {
        this.$toast.error(errorMessage);
        return false;
      }

      return true;
    },
  },
};
</script>
