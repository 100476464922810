var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page page--player" },
    [
      _c("page-title", { attrs: { title: "Player" } }),
      _c("div", { staticClass: "container" }, [
        _c("h2", [_vm._v("Main color:")]),
        _c("p", [
          _vm._v(
            " Select the primary color that will be displayed for the controls in the video player "
          ),
        ]),
        _c(
          "p",
          [
            _c("color-picker-input", {
              model: {
                value: _vm.library.player_style["color-main"],
                callback: function ($$v) {
                  _vm.$set(_vm.library.player_style, "color-main", $$v)
                },
                expression: "library.player_style['color-main']",
              },
            }),
          ],
          1
        ),
        _c("hr", { staticClass: "separator" }),
        _c(
          "h2",
          [
            _c("on-off", {
              attrs: { type: "big" },
              model: {
                value: _vm.library.player_show_title,
                callback: function ($$v) {
                  _vm.$set(_vm.library, "player_show_title", $$v)
                },
                expression: "library.player_show_title",
              },
            }),
            _vm._v(" Show video title "),
          ],
          1
        ),
        _c("hr", { staticClass: "separator" }),
        _c(
          "button",
          { staticClass: "button", on: { click: _vm.onClickFileUpload } },
          [_vm._m(0)]
        ),
        _c("input", {
          ref: "player_logo",
          attrs: { type: "file", hidden: "", accept: "image/*" },
          on: { change: _vm.previewFile },
        }),
        _vm.playerLogoUrl
          ? _c("div", {
              staticClass: "player_logo",
              style: _vm.playerLogoStyle,
            })
          : _vm._e(),
        _c(
          "h2",
          [
            _c("on-off", {
              attrs: { type: "big" },
              model: {
                value: _vm.library.player_show_logo,
                callback: function ($$v) {
                  _vm.$set(_vm.library, "player_show_logo", $$v)
                },
                expression: "library.player_show_logo",
              },
            }),
            _vm._v(" Show logo "),
          ],
          1
        ),
        _c("p", [
          _c(
            "button",
            { staticClass: "button primary", on: { click: _vm.save } },
            [_vm._m(1), _vm._v(" Save ")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fa fa-fw fa-plus" }),
      _vm._v(" Upload logo "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fa fa-save" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }