var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-header" }, [
    _c(
      "div",
      { staticClass: "navbar" },
      [
        _c("router-link", { staticClass: "logo", attrs: { to: "/" } }, [
          _c("i", { staticClass: "icon header-logo" }),
        ]),
        _c(
          "div",
          {
            staticClass: "profile",
            on: {
              click: function ($event) {
                return _vm.openMenu()
              },
            },
          },
          [
            _vm.user
              ? _c("span", { staticClass: "user" }, [
                  _vm._v(_vm._s(_vm.user.name)),
                ])
              : _vm._e(),
            _c("i", { staticClass: "icon profile-avatar" }),
            _c("i", { staticClass: "icon dropdown-arrow" }),
          ]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "dropdown", class: { opened: _vm.isOpened } }, [
      _c("ul", [_c("li", { on: { click: _vm.logOut } }, [_vm._v("Log Out")])]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }