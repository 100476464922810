var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "rename-playlist-modal",
      attrs: { name: "rename-playlist" },
      on: {
        "before-open": function ($event) {
          _vm.playlistName = _vm.playlist.name
        },
      },
    },
    [
      _c("div", { staticClass: "upper" }, [
        _c("h2", [_vm._v("Rename playlist")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.playlistName,
              expression: "playlistName",
            },
          ],
          attrs: { type: "text", placeholder: "Write a playlist name..." },
          domProps: { value: _vm.playlistName },
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.renamePlaylist.apply(null, arguments)
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.playlistName = $event.target.value
            },
          },
        }),
      ]),
      _c("div", { staticClass: "buttons" }, [
        _c(
          "button",
          { staticClass: "button primary", on: { click: _vm.renamePlaylist } },
          [_vm._v("Rename")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }