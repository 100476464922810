<template>
  <div id="app">
    <template v-if="!isLoggedIn">
      <div class="center-content">
        <router-view />
      </div>
    </template>

    <template v-else>
      <div v-if="isLoading" class="loader">
        <i />
      </div>

      <template v-else>
        <app-header />
        <component :is="sidebarComponent" />
        <div class="main-content">
          <router-view />

          <video-upload-modal />
          <create-playlist-modal />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue';
import HomeSidebar from '@/components/HomeSidebar.vue';
import Sidebar from '@/components/Sidebar.vue';
import VideoUploadModal from '@/components/VideoUploadModal.vue';
import CreatePlaylistModal from './components/CreatePlaylistModal.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    HomeSidebar,
    Sidebar,
    VideoUploadModal,
    CreatePlaylistModal,
  },
  computed: {
    isLoading() {
      return this.$store.getters['app/isLoading'];
    },
    isLoggedIn() {
      return this.$store.getters['auth/loggedIn'];
    },
    sidebarComponent() {
      return this.$route.meta && this.$route.meta.sidebar === 'home'
        ? HomeSidebar
        : Sidebar;
    },
  },
  created() {
    if (this.isLoggedIn) {
      this.fetchAllData();
    }
  },
  watch: {
    isLoggedIn(val, oldVal) {
      if (val && oldVal === false) {
        this.fetchAllData();
      }
    },
    // If change the library, the playlists will be fetched immediately
    '$route.params.libraryId': {
      handler: function(libraryId) {
        if (libraryId) {
          this.$store.dispatch('playlist/getList', libraryId);
          this.$store.dispatch('library/get', libraryId);
        }
      },
      deep: false,
      immediate: false
    }
  },
  methods: {
    fetchAllData() {
      const promises = [
        this.$store.dispatch('library/getList'),
        this.$store.dispatch('auth/fetch'),
      ];

      const libraryId = this.$route.params.libraryId;
      if (libraryId) {
        promises.push(this.$store.dispatch('playlist/getList', libraryId));
        promises.push(this.$store.dispatch('library/get', libraryId));
      }

      this.$store.commit('app/isLoading', true);
      Promise.all(promises)
        .catch(() =>
          this.$toast.error('Hiba történt az adatok betöltése közben')
        )
        .finally(() => this.$store.commit('app/isLoading', false));
    },
  },
};
</script>
