var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page page--add-library" },
    [
      _c("page-title", {
        attrs: {
          title: "Add Video Library",
          showPlaylistButton: false,
          showUploadButton: false,
        },
      }),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "name-library" }, [
          _c("h5", [_vm._v("Name")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.entity.name,
                expression: "entity.name",
              },
            ],
            attrs: { type: "text", placeholder: "My Video Library" },
            domProps: { value: _vm.entity.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.entity, "name", $event.target.value)
              },
            },
          }),
          _c(
            "button",
            {
              staticClass: "button primary",
              class: _vm.buttonClass,
              on: {
                click: function ($event) {
                  return _vm.addLibrary()
                },
              },
            },
            [
              _vm.isLoading
                ? _c("pulse-loader", {
                    attrs: {
                      loading: _vm.isLoading,
                      color: "#ffffff",
                      size: "6px",
                    },
                  })
                : [
                    _c("i", { staticClass: "fa fa-fw fa-plus" }),
                    _vm._v("Add Video Library "),
                  ],
            ],
            2
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }