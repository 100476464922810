<template>
  <modal
    name="rename-playlist"
    class="rename-playlist-modal"
    @before-open="playlistName = playlist.name"
  >
    <div class="upper">
      <h2>Rename playlist</h2>
      <input
        type="text"
        placeholder="Write a playlist name..."
        v-model="playlistName"
        @keyup.enter="renamePlaylist"
      />
    </div>
    <div class="buttons">
      <button class="button primary" @click="renamePlaylist">Rename</button>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'RenamePlaylistModal',
  data: () => ({
    playlistName: '',
  }),
  computed: {
    playlistId() {
      return this.$route.params.playlistId;
    },
    playlist() {
      return this.$store.getters['playlist/get'](this.playlistId);
    },
  },
  methods: {
    hideModal() {
      this.$modal.hide('rename-playlist');
    },
    renamePlaylist() {
      if (!this.playlistName) {
        this.$toast.error('Invalid playlist name!')
      } else {
        this.playlist.name = this.playlistName;
        this.$store.dispatch('playlist/update', this.playlist)
          .then(() => {
            this.$toast.success('Renamed playlist!');
            this.hideModal();
          });
      }
    },
  },
};
</script>
