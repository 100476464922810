var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.wrapperClasses }, [
    _c("input", {
      attrs: {
        type: "text",
        placeholder: _vm.inputPlaceholder,
        disabled: _vm.inputDisabled,
      },
      domProps: { value: _vm.value },
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.$emit("click")
        },
        input: _vm.input,
      },
    }),
    _c(
      "button",
      {
        staticClass: "button primary",
        attrs: { disabled: _vm.buttonDisabled },
        on: {
          click: function ($event) {
            return _vm.$emit("click")
          },
        },
      },
      [_c("span", { domProps: { innerHTML: _vm._s(_vm.buttonLabel) } })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }