<template>
  <div class="thumbnail-list">
    <div class="active">
      <div :class="{ thumbnail: true, 'is-loading': isLoading }">
        <pulse-loader
          v-if="isLoading"
          :loading="isLoading"
          color="#04223e"
          size="6px"
        />
        <img :src="video.thumbnail_url" />
      </div>
    </div>
    <div class="list">
      <div
        v-for="thumbnail in generatedThumbnails"
        :key="thumbnail.id"
        class="thumbnail selectable"
        v-tooltip.bottom="'Set thumbnail'"
        @click="selectThumbnail(thumbnail.id)"
      >
        <img :src="thumbnail.url" />
      </div>
      <div class="thumbnail upload" v-tooltip.bottom="'Upload new thumbnail'">
        <i class="fad fa-upload"></i>
        <label for="new-thumbnail"></label>
        <input
          type="file"
          id="new-thumbnail"
          @change="uploadFile"
          accept="image/png, image/gif, image/jpeg"
        />

        <!-- hidden, only for container size -->
        <img :src="video.thumbnail_url" />
      </div>
    </div>
  </div>
</template>

<script>
import Video from '@/entities/Video';
import VideoThumbnailService from '@/services/VideoThumbnail';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
  name: 'ThumbnailList',
  components: {
    PulseLoader,
  },
  props: {
    video: {
      type: Video,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    generatedThumbnails() {
      return this.video.thumbnails.filter(item => !item.uploaded);
    }
  },
  methods: {
    selectThumbnail(thumbnailId) {
      this.isLoading = true;
      VideoThumbnailService.select(this.video.id, thumbnailId)
        .then((resp) => {
          this.video.updateWith(resp);
          this.$emit('updated');
        })
        .finally(() => this.isLoading = false);
    },
    uploadFile(e) {
      const file = e.target.files[0];

      this.isLoading = true;
      VideoThumbnailService.upload(this.video.id, file)
        .then((resp) => {
          this.video.updateWith(resp);
          this.$emit('updated');
        })
        .finally(() => this.isLoading = false);
    }
  }
}
</script>
