<template>
  <div class="auth-page reset-password-view">
    <logo/>
    <div class="content">
      <div class="title">Jelszó helyreállítása</div>
      <div class="credentials">
        <input
          v-model="email"
          type="email"
          class="big"
          placeholder="E-mail cím megadása"
          readonly
        />
        <input
          v-model="password"
          type="password"
          class="big"
          placeholder="Jelszó"
        />
        <input
          v-model="passwordAgain"
          type="password"
          class="big"
          placeholder="Jelszó megerősítése"
        />
        <button class="button big" @click="submit">
          <pulse-loader
            v-if="isLoading"
            :loading="isLoading"
            color="#ffffff"
            size="6px"
          />
          <template v-else>Jelszó beállítása</template>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Logo from '@/components/Logo.vue';

export default {
  name: 'ResetPassword',
  components: {
    Logo,
    PulseLoader,
  },
  data() {
    return {
      isLoading: false,
      password: null,
      passwordAgain: null,
      token: this.$route.params.token,
      email: this.$route.query.email,
    };
  },
  methods: {
    submit() {
      if (!this.validate()) {
        return;
      }

      this.isLoading = true;
      this.$store
        .dispatch('auth/resetPassword', {
          email: this.email,
          token: this.token,
          password: this.password,
        })
        .then(() => {
          this.$toast.success(
            'A jelszó beállítása sikeres volt. Most már bejelentkezhet.'
          );
          this.$router.replace('/auth/login');
        })
        .catch((err) => {
          this.$toast.error(err.message);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    validate() {
      this.$toast.clear();

      if (this.password && !this.passwordAgain) {
        this.$toast.error('A jelszó megerősítése kötelező!');
        return false;
      }
      else if (!this.password && this.passwordAgain) {
        this.$toast.error('Jelszó megadása kötelező!');
        return false;
      }
      else if (this.password !== this.passwordAgain) {
        this.$toast.error('A jelszavak nem egyeznek!');
        return false;
      }
      else if (this.password.length < 8) {
        this.$toast.error('A jelszónak legalább 8 karakterből kell állnia!');
        return false;
      }

      return true;
    },
  },
};
</script>
