import Api from '@/services/Api';

export default {

  /**
   * @param {integer} videoId 
   * @param {integer} thumbailId 
   * @returns 
   */
  select(videoId, thumbailId) {
    const data = {
      thumbnail_id: thumbailId,
    };

    return Api()
      .post('/video/' + videoId + '/thumbnail/select', data)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  /**
   * @param {integer} videoId 
   * @param {File} file 
   * @returns 
   */
   upload(videoId, file) {
    const fd = new FormData();
    fd.append('file', file);

    return Api()
      .post('/video/' + videoId + '/thumbnail/upload', fd, {'Content-Type': 'multipart/form-data'})
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },


  
};
