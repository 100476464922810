var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "auth-page signup-form-view" },
    [
      _c("logo"),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Regisztráció")]),
        _vm.isLoading
          ? _c("div", { staticClass: "loader" }, [_c("i")])
          : _vm.invalidLink
          ? _c("div", [_vm._v(" Érvénytelen meghívó link. ")])
          : _c("div", { staticClass: "credentials" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email",
                  },
                ],
                staticClass: "big",
                attrs: {
                  type: "email",
                  placeholder: "E-mail cím megadása",
                  readonly: "",
                },
                domProps: { value: _vm.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.email = $event.target.value
                  },
                },
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.name,
                    expression: "name",
                  },
                ],
                staticClass: "big",
                attrs: {
                  type: "text",
                  placeholder: "Név megadása",
                  readonly: "",
                },
                domProps: { value: _vm.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.name = $event.target.value
                  },
                },
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password",
                  },
                ],
                staticClass: "big",
                attrs: { type: "password", placeholder: "Jelszó" },
                domProps: { value: _vm.password },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.password = $event.target.value
                  },
                },
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.passwordAgain,
                    expression: "passwordAgain",
                  },
                ],
                staticClass: "big",
                attrs: { type: "password", placeholder: "Jelszó megerősítése" },
                domProps: { value: _vm.passwordAgain },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.passwordAgain = $event.target.value
                  },
                },
              }),
              _c("div", { staticClass: "text" }, [
                _vm._v(
                  " A regisztrációval elfogadom a szolgáltatási feltételeit, és tudomásul veszem az Adatvédelmi irányelveket. "
                ),
              ]),
              _c(
                "button",
                { staticClass: "button big", on: { click: _vm.submit } },
                [
                  _vm.isSaving
                    ? _c("pulse-loader", {
                        attrs: {
                          loading: _vm.isSaving,
                          color: "#ffffff",
                          size: "6px",
                        },
                      })
                    : [_vm._v("Regisztrálok")],
                ],
                2
              ),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }