<template>
  <div class="sidebar">
    <div class="title">Libraries</div>

    <div class="functions">
      <router-link to="/">
        <i class="fa fa-fw fa-photo-video" />
        Manage Libraries
      </router-link>
      <router-link to="/library/add">
        <i class="fa fa-fw fa-plus" />
        Add Video Library
      </router-link>
      <router-link to="/users" v-if="currentUserIsSuperadmin">
        <i class="far fa-fw fa-users-crown" />
        Manage Users
      </router-link>
    </div>
  </div>
</template>

<script>
import UserMixin from '@/mixins/user';

export default {
  name: 'HomeSidebar',
  mixins: [ UserMixin ],
}
</script>
