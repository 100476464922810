<template>
  <div class="page page--playlist">
    <page-title title="Manage Playlist" />
    <div v-if="playlist" class="container">
      <div>
        <button class="button clean delete" @click="showDeleteConfirmPlaylist()">
          <i class='fa fa-trash'></i>
          Delete
        </button>
      </div>
      <div class="title">
        <button @click="showPlaylistRenameModal" class="button icon-only clean">
          <i class="fad fa-pencil"></i>
        </button>
        {{ playlist.name }}
        <span :class="spanClass">
          <i class="fad fa-play"></i>
          {{ videoNumber }}
        </span>
      </div>
      <div class="item-list-container">
        <div v-show="isLoading" class="loader">
          <i />
        </div>
        <draggable
          class="item-list"
          :force-fallback="true"
          :fallback-tolerance="50"
          @end="updateVideoOrder"
        >
          <playlist-video-item
            v-for="video in videos"
            :key="video.id"
            :video="video"
            :playlist="playlist"
            @click="openVideoModal"
          />
        </draggable>
      </div>

      <template v-if="videos.length">
        <ul class="tab-navigation">
          <li :class="{ active: activeTab === 'embed' }" @click="activeTab = 'embed'">
            <i class="fad fa-code" /> Embed
          </li>
          <li :class="{ active: activeTab === 'advertising' }" @click="activeTab = 'advertising'">
            <i class="fad fa-ad" /> Advertising
          </li>
        </ul>
        <div v-show="activeTab === 'embed'" class="embed-tab">
          <div class="video-preview">
            <div :id="`summit-player--${playlistId}`"></div>
          </div>
          <div class="embed-code">
            <p>Use this code inside of your website HTML to display the player.</p>
            <div class="switches">
              <on-off v-model="autoplay" @change="checkAutoplay" /> Autoplay
              <on-off v-model="muted" @change="checkMuted" /> Muted
            </div>
            <textarea readonly v-html="embedCode" @click="copyToClipboard(embedCode)" />
          </div>
        </div>
        <div v-show="activeTab === 'advertising'" class="advertising-tab">
          <div class="disable-ads-container">
            <on-off v-model="playlist.disable_ads" />
            <p>Disable ads</p>
          </div>
          <p>Here you can enter a unique VAST Tag URL for the playlist</p>
          <textarea v-model="playlist.vast_tag_url" />
          <button class="button primary" @click="save">
            <span class="icon">
              <i class="fa fa-save"></i>
            </span>
            Save
          </button>
        </div>
      </template>
    </div>

   <video-modal :video-id="videoId" />
   <rename-playlist-modal />
  </div>
</template>

<script>
import OnOff from '@/components/OnOff.vue';
import PageTitle from '@/components/PageTitle.vue';
import RenamePlaylistModal from '@/components/RenamePlaylistModal.vue';
import PlaylistVideoItem from '@/components/PlaylistVideoItem.vue';
import VideoModal from '@/components/VideoModal.vue';

import PlaylistService from '@/services/Playlist';

import draggable from 'vuedraggable'
import Swal from 'sweetalert2/dist/sweetalert2.js';

export default {
  name: 'Playlist',
  components: {
    draggable,
    OnOff,
    PageTitle,
    RenamePlaylistModal,
    PlaylistVideoItem,
    VideoModal,
  },
  data: () => ({
    isLoading: false,
    videoId: null,

    activeTab: 'embed',
    previewIsInited: false,
    autoplay: true,
    muted: true,
  }),
  computed: {
    libraryId() {
      return this.$route.params.libraryId;
    },
    playlistId() {
      return this.$route.params.playlistId;
    },
    playlist() {
      return this.$store.getters['playlist/get'](this.playlistId);
    },
    videos() {
      return this.playlist && this.playlist.videos.length ? this.playlist.videos : [];
    },
    spanClass() {
      return this.playlist.videos.length == 0 ? 'empty' : '';
    },
    videoNumber() {
      return this.playlist.videos.length + ' ' + (this.playlist.videos.length == 1 ? 'video' : 'videos');
    },
    playerUrl() {
      return process.env.VUE_APP_PLAYER_URL;
    },
    embedCode() {
      const props = [];
      props.push('type: \'playlist\'');
      if (this.autoplay) {
        props.push('autoplay: true');
      }
      if (this.muted) {
        props.push('muted: true');
      }

      return '<div id="summit-player--' + this.playlistId + '"></div>' + "\r\n" +
        '<script>' + "\r\n" +
        'window.summitplayer = window.summitplayer || { players: {} };' + "\r\n" +
        'window.summitplayer.players[\'' + this.playlistId + '\'] = { ' + props.join(', ') + ' };' + "\r\n" +
        '</sc' + 'ript>' + "\r\n" +
        '<script src="' + this.playerUrl + '/embed.js" defer></sc' + 'ript>';
    },
  },
  created() {
    this.$store.dispatch('playlist/get', this.playlistId);
  },
  mounted() {
    this.initPreview();
  },
  methods: {
    showDeleteConfirmPlaylist() {
      Swal.fire({
        title: 'Delete playlist?',
        text: 'Are you sure you want to delete this playlist?',
        icon: 'warning',
        showCancelButton: true,
        focusConfirm: false,
        reverseButtons: true,
        confirmButtonColor: '#BC4545',
        cancelButtonColor: '#B9B9B9',
        confirmButtonText: 'Delete playlist',
      }).then((result) => {
        if (result.isConfirmed) {
          this.deletePlaylist();
        }
      });
    },
    deletePlaylist() {
      this.$store.dispatch('playlist/delete', this.playlist).then(() => {
        this.$router.replace({path: `/library/${this.libraryId}/playlists`});
      });
    },
    showPlaylistRenameModal() {
      this.$modal.show('rename-playlist');
    },
    openVideoModal(videoId) {
      this.videoId = videoId;
      this.$modal.show('video-modal');
    },
    initPreview(forcePreview) {
      if (!this.previewIsInited || forcePreview) {
        if (typeof window.summitplayer === 'undefined') {
          window.summitplayer = { players: {}, buildPlayers() {} };

          const script = document.createElement('script');
          script.setAttribute('src', this.playerUrl + '/embed.js');
          document.head.appendChild(script);
        }
        else {
          window.summitplayer.players = {};
        }

        window.summitplayer.players[this.playlistId] = {
          type: 'playlist',
          autoplay: this.autoplay,
          muted: this.muted,
        };

        setTimeout(() => window.summitplayer.buildPlayers(), 500);

        this.previewIsInited = true;
      }
    },
    checkAutoplay() {
      if (this.autoplay) {
        this.muted = true;
      }
      this.initPreview(true);
    },
    checkMuted() {
      if (!this.muted) {
        this.autoplay = false;
      }
      this.initPreview(true);
    },
    async copyToClipboard(text) {
      await navigator.clipboard.writeText(text);
      this.$toast.clear();
      this.$toast.success('Copied to clipboard', { duration: 2000 });
    },
    updateVideoOrder(event) {
      const videoId = event.item.getAttribute('data-video-id');
      const order = event.newIndex + 1;

      if (event.newIndex !== event.oldIndex) {
        this.isLoading = true;
        PlaylistService.setVideoOrder(this.playlistId, videoId, order)
          .finally(() => this.isLoading = false);
      }
    },
    save(showToast, refreshPreview) {
      PlaylistService.update(this.playlist)
        .then(() => {
          if (showToast) {
            this.$toast.clear();
            this.$toast.success('The playlist was updated');
          }
          if (refreshPreview) {
            this.initPreview(true);
          }
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
  },
};
</script>
