<template>
  <div :class="wrapperClasses">
    <input
      type="text"
      :placeholder="inputPlaceholder"
      :disabled="inputDisabled"
      :value="value"
      @keyup.enter="$emit('click')"
      @input="input"
    />
    <button
      class="button primary"
      :disabled="buttonDisabled"
      @click="$emit('click')"
    >
      <span v-html="buttonLabel"></span>
    </button>
  </div>
</template>
<script>
export default {
  name: 'InputWithButton',
  props: {
    value: {
      required: true,
    },
    inputPlaceholder: {
      type: String,
      required: false,
    },
    buttonLabel: {
      type: String,
      required: true,
    },
    inputDisabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    buttonDisabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    //click: call parent method eg: @click="function"
  },
  computed: {
    wrapperClasses() {
      return {
        'input-with-button': true,
        'input-disabled': this.inputDisabled,
        'button-disabled': this.buttonDisabled,
      };
    },
  },
  methods: {
    input(event) {
      this.$emit('input', event.target.value)
    }
  },
};
</script>
