var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sidebar" },
    [
      _c("div", { staticClass: "title" }, [
        _c(
          "button",
          {
            staticClass: "button icon-only clean",
            on: { click: _vm.showLibraryRenameModal },
          },
          [_c("i", { staticClass: "fad fa-pencil" })]
        ),
        _vm._v(" " + _vm._s(_vm.library.name) + " "),
      ]),
      _c("rename-library-modal"),
      _c(
        "div",
        { staticClass: "functions" },
        [
          _c("router-link", { attrs: { to: `/library/${_vm.library.id}` } }, [
            _c("i", { staticClass: "fa fa-fw fa-photo-video" }),
            _vm._v(" Manage Videos "),
          ]),
          _c(
            "router-link",
            { attrs: { to: `/library/${_vm.library.id}/playlists` } },
            [
              _c("i", { staticClass: "far fa-fw fa-list" }),
              _vm._v(" Manage Playlists "),
            ]
          ),
          _c(
            "router-link",
            { attrs: { to: `/library/${_vm.library.id}/player` } },
            [_c("i", { staticClass: "far fa-fw fa-play" }), _vm._v(" Player ")]
          ),
          _c(
            "router-link",
            { attrs: { to: `/library/${_vm.library.id}/stat` } },
            [
              _c("i", { staticClass: "far fa-fw fa-chart-area" }),
              _vm._v(" Statistics "),
            ]
          ),
          _c(
            "router-link",
            { attrs: { to: `/library/${_vm.library.id}/advertising` } },
            [
              _c("i", { staticClass: "far fa-fw fa-ad" }),
              _vm._v(" Advertising "),
            ]
          ),
          _c(
            "router-link",
            { attrs: { to: `/library/${_vm.library.id}/security` } },
            [
              _c("i", { staticClass: "far fa-fw fa-shield-alt" }),
              _vm._v(" Security "),
            ]
          ),
          _c(
            "router-link",
            { attrs: { to: `/library/${_vm.library.id}/gemius` } },
            [
              _c("i", { staticClass: "far fa-network-wired" }),
              _vm._v(" Gemius "),
            ]
          ),
          _c(
            "router-link",
            { attrs: { to: `/library/${_vm.library.id}/delete` } },
            [
              _c("i", { staticClass: "far fa-fw fa-trash-alt" }),
              _vm._v(" Delete Library "),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }