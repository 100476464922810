var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "create-playlist-modal",
      attrs: { name: "create-playlist", height: "auto" },
      on: { "before-open": _vm.clearModal },
    },
    [
      _c("div", { staticClass: "upper" }, [
        _c("h2", [_vm._v("Create Playlist")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.playlistName,
              expression: "playlistName",
            },
          ],
          attrs: { type: "text", placeholder: "Playlist name" },
          domProps: { value: _vm.playlistName },
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.createPlayList.apply(null, arguments)
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.playlistName = $event.target.value
            },
          },
        }),
      ]),
      _c("div", { staticClass: "buttons" }, [
        _c(
          "button",
          { staticClass: "button primary", on: { click: _vm.createPlayList } },
          [
            _vm.isLoading
              ? _c("pulse-loader", {
                  attrs: {
                    loading: _vm.isLoading,
                    color: "#ffffff",
                    size: "6px",
                  },
                })
              : [_vm._v("Create")],
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }