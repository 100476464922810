<template>
  <div class="page" v-if="video">
    <router-link :to="`/video/${video.id}`"><button>Back</button></router-link>
    <hr>

    Video

    <pre>{{ video }}</pre>

    <hr>

    Name
    <input v-model="video.name" type="text">

    Vast Tag URL:
    <input v-model="video.vast_tag_url" type="text">

    <button @click="save">Mentés</button>
  </div>
</template>

<script>
import Video from '@/entities/Video';
import VideoService from '@/services/Video';

export default {
  name: 'UploadVideo',
  data: () => ({
    video: null,
    name: '',
    vastTagUrl: '',
  }),
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    VideoService.get(this.id).then((resp) => {
      this.video = new Video(resp);
    });
  },
  methods: {
    save() {
      VideoService.update(this.video).then(() => {
        console.log('saved')
      });
    }
  },
};
</script>
