var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.library
    ? _c(
        "modal",
        {
          staticClass: "upload-video-modal",
          attrs: { name: "upload-video", height: "auto" },
        },
        [
          _c(
            "div",
            {
              ref: "drop-zone",
              staticClass: "drop-zone",
              on: {
                drop: function ($event) {
                  $event.preventDefault()
                  return _vm.onDropFile.apply(null, arguments)
                },
                dragenter: function ($event) {
                  $event.preventDefault()
                  return _vm.onDragEnter.apply(null, arguments)
                },
                dragover: function ($event) {
                  $event.preventDefault()
                  return _vm.onDragEnter.apply(null, arguments)
                },
                dragleave: function ($event) {
                  $event.preventDefault()
                  return _vm.onDragLeave.apply(null, arguments)
                },
                dragend: function ($event) {
                  $event.preventDefault()
                  return _vm.onDragLeave.apply(null, arguments)
                },
              },
            },
            [
              _c("label", { attrs: { for: "file" } }, [
                _c("i", { staticClass: "fad fa-5x fa-file-upload" }),
                _c("h3", [_vm._v("Upload Videos")]),
                _vm._v(" Drop your video file here, or "),
                _c("span", { staticClass: "orange" }, [_vm._v("browse")]),
              ]),
              _c("input", {
                attrs: {
                  type: "file",
                  id: "file",
                  accept:
                    ".mp4,.mkv,.webm,.flv,.vod,.avi,.mov,.wmv,.amv,.m4p,.mpeg,.mpg,.4mv",
                },
                on: { change: _vm.uploadFile },
              }),
            ]
          ),
          _vm.fileUploadStatus !== null
            ? _c("div", { staticClass: "file-upload-status" }, [
                _vm.fileUploadStatus === "Uploading"
                  ? _c("div", { staticClass: "info" }, [
                      _vm._v(_vm._s(_vm.uploadProgress + "%")),
                    ])
                  : _vm._e(),
                _vm.fileUploadStatus === "Finished"
                  ? _c("div", { staticClass: "info finished" }, [
                      _c("i", { staticClass: "fa fa-check" }),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "file-name" }, [
                  _vm._v(_vm._s(_vm.file.name)),
                ]),
                _c("div", { staticClass: "status" }, [
                  _vm._v(_vm._s(_vm.fileUploadStatus)),
                ]),
                _c("div", { staticClass: "progressbar" }, [
                  _c("div", { style: { width: _vm.uploadProgress + "%" } }),
                ]),
              ])
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }