var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page page--gemius" },
    [
      _c("page-title", { attrs: { title: "Advertising" } }),
      _c("div", { staticClass: "container" }, [
        _c("h2", [_vm._v("Gemius ID:")]),
        _c("p", [_vm._v(" Place your Gemius ID below here. ")]),
        _c(
          "p",
          [
            _c("input-with-button", {
              attrs: { buttonLabel: "<i class='fa fa-save'></i> Save" },
              on: { click: _vm.save },
              model: {
                value: _vm.library.gemius_id,
                callback: function ($$v) {
                  _vm.$set(_vm.library, "gemius_id", $$v)
                },
                expression: "library.gemius_id",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }