<template>
  <div class="page page--security">
    <page-title title="Security" />
    <div class="container">
      <h2>Allowed domains</h2>
      <p>
        The list of domains that are allowed to access the video.
        If no hostnames are listed, all requests will be allowed.
      </p>
      <p>
        <input-with-button
          v-model="insertedValue"
          buttonLabel="<i class='fa fa-plus'></i> Add"
          @click="addDomain"
          inputPlaceholder="Example: mywebsite.com"
        />
      </p>
        <ul class="domain-list">
          <li v-for="domain in domains" :key="domain">
            {{ domain }}
            <i class="fa fa-times" @click="removeDomain(domain)" />
          </li>
        </ul>
      <p>
        <button class="button primary" @click="save">
          <span class="icon">
            <i class="fa fa-save"></i>
          </span>
          Save
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import InputWithButton from '@/components/InputWithButton';

export default {
  name: 'Security',
  components: {
    PageTitle,
    InputWithButton
  },
  data() {
    return {
      domains: [],
      insertedValue: '',
    }
  },
  computed: {
    libraryId() {
      return this.$route.params.libraryId;
    },
    library() {
      return this.$store.getters['library/get'](this.libraryId);
    }
  },
  watch: {
    library: {
      handler() {
        if (
          this.domains.length == 0 &&
          this.library &&
          this.library.allowed_domains &&
          this.library.allowed_domains.length !== 0
        ) {
          this.domains = this.library.allowed_domains;
        }
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    addDomain() {
      if (!this.insertedValue) {
        this.$toast.error('Cannot add empty input!');
        return;
      }

      if (this.domains.includes(this.insertedValue)) {
        this.$toast.error('Already have a domain like that!');
        return;
      }

      this.domains.push(this.insertedValue);
      this.insertedValue = '';
    },
    removeDomain(domain) {
      const index = this.domains.indexOf(domain);

      if (index !== -1) {
        this.domains.splice(index, 1);
      }
    },
    save() {
      this.library.allowed_domains = this.domains;

      this.$store.dispatch('library/save', this.library)
        .then(() => {
          this.$toast.success('Domain list updated');
        })
        .catch(() =>
          this.$toast.error('Invalid domain!')
        );
    }
  },
};
</script>
