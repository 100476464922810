import Vue from 'vue';
import VueRouter from 'vue-router';
import { store } from '@/store';

import Login from '@/views/auth/Login';
import Signup from '@/views/auth/Signup';
import ForgottenPassword from '@/views/auth/ForgottenPassword';
import ResetPassword from '@/views/auth/ResetPassword';

import LibraryList from '@/views/library/LibraryList';
import AddLibrary from '@/views/library/AddLibrary';
import DeleteLibrary from '@/views/library/DeleteLibrary';
import Advertising from '@/views/library/Advertising';
import Gemius from '@/views/library/Gemius';
import ManageVideos from '@/views/library/ManageVideos';
import ManagePlaylists from '@/views/library/ManagePlaylists';
import Playlist from '@/views/library/Playlist';
import Player from '@/views/library/Player';
import Security from '@/views/library/Security';
import Statistics from '@/views/library/Statistics';
import Video from '@/views/library/Video';

import AddUser from '@/views/user/Add';
import EditUser from '@/views/user/Edit';
import UserList from '@/views/user/List';

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [

    // Auth pages
    {
      path: '/auth/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/auth/signup/:hash',
      name: 'Signup',
      component: Signup,
    },
    {
      path: '/auth/reset-password/:token',
      name: 'ResetPassword',
      component: ResetPassword,
    },
    {
      path: '/auth/forgotten-password',
      name: 'ForgottenPassword',
      component: ForgottenPassword,
    },

    // Library and video pages
    {
      path: '/',
      name: 'LibraryList',
      meta: {
        sidebar: 'home',
      },
      component: LibraryList,
    },
    {
      path: '/library/add',
      name: 'AddLibrary',
      meta: {
        sidebar: 'home',
      },
      component: AddLibrary,
    },
    {
      path: '/library/:libraryId',
      name: 'ManageVideos',
      component: ManageVideos,
    },
    {
      path: '/library/:libraryId/playlists',
      name: 'ManagePlaylists',
      component: ManagePlaylists,
    },
    {
      path: '/library/:libraryId/playlist/:playlistId',
      name: 'Playlist',
      component: Playlist,
    },
    {
      path: '/library/:libraryId/advertising',
      name: 'Advertising',
      component: Advertising,
    },
    {
      path: '/library/:libraryId/player',
      name: 'Player',
      component: Player,
    },
    {
      path: '/library/:libraryId/stat',
      name: 'Statistics',
      component: Statistics,
    },
    {
      path: '/library/:libraryId/security',
      name: 'Security',
      component: Security,
    },
    {
      path: '/library/:libraryId/delete',
      name: 'Delete',
      component: DeleteLibrary,
    },
    {
      path: '/video/:id',
      name: 'Video',
      component: Video,
    },
    {
      path: '/library/:libraryId/gemius',
      name: 'Gemius',
      component: Gemius,
    },

    // User pages
    {
      path: '/users',
      name: 'UserList',
      meta: {
        sidebar: 'home',
      },
      component: UserList,
    },
    {
      path: '/user/add',
      name: 'AddUser',
      meta: {
        sidebar: 'home',
      },
      component: AddUser,
    },
    {
      path: '/user/:id',
      name: 'EditUser',
      meta: {
        sidebar: 'home',
      },
      component: EditUser,
    },

    // Others
    {
      path: '*',
      redirect: '/',
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const publicViewNames = [
    'Login',
    'Signup',
    'ForgottenPassword',
    'ResetPassword',
  ];

  const loggedIn = store.state.auth.loggedIn;
  const toPublicView = publicViewNames.includes(to.name);

  if (!loggedIn && !toPublicView) {
    return next('/auth/login');
  }
  if (loggedIn && toPublicView) {
    return next('/');
  }

  next();
});
