<template>
  <div class="page page--add-library">
    <page-title
      title="Add Video Library"
      :showPlaylistButton="false"
      :showUploadButton="false"
    />
    <div class="container">
      <div class="name-library">
        <h5>Name</h5>
        <input
          type="text"
          placeholder="My Video Library"
          v-model="entity.name"
        />
        <button
          class="button primary"
          :class="buttonClass"
          @click="addLibrary()"
        >
          <pulse-loader
            v-if="isLoading"
            :loading="isLoading"
            color="#ffffff"
            size="6px"
          />
          <template v-else>
            <i class="fa fa-fw fa-plus" />Add Video Library
          </template>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Library from '@/entities/Library.js';

export default {
  name: 'AddLibrary',
  components: {
    PageTitle,
    PulseLoader,
  },
  data: () => ({
    entity: new Library(),
    isLoading: false,
  }),
  computed: {
    isButtonDisabled() {
      return this.entity.name === '';
    },
    buttonClass() {
      return {
        disabled: this.isButtonDisabled,
      };
    },
  },
  methods: {
    addLibrary() {
      if (!this.isButtonDisabled) {
        this.isLoading = true;
        this.$store
          .dispatch('library/save', this.entity)
          .then(() => {
            this.$router.replace({ path: '/library' });
            this.libraryName = '';
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
