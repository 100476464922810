<template>
  <div class="page page--delete-library">
    <page-title title="Delete Library" />
    <div class="container">
      <h4>Delete Video Library?</h4>
      <p class="help-paragraph">
        Deleting the library will immediately remove all uploaded videos videos.
        This action cannot be undone.
      </p>
      <button class="button" @click="showDeleteConfirmLibrary()">
        <i class="fa fa-trash" />Delete Video Library
      </button>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';

export default {
  name: 'DeleteLibrary',
  components: {
    PageTitle,
  },
  computed: {
    libraryId() {
      return this.$route.params.libraryId;
    },
    library() {
      return this.$store.getters['library/get'](this.libraryId);
    },
  },
  methods: {
    showDeleteConfirmLibrary() {
      Swal.fire({
        title: 'Delete library?',
        text: 'Are you sure you want to delete the library?',
        icon: 'warning',
        showCancelButton: true,
        focusConfirm: false,
        reverseButtons: true,
        confirmButtonColor: '#BC4545',
        cancelButtonColor: '#B9B9B9',
        confirmButtonText: 'Delete library',
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteLibrary();
        }
      });
    },
    deleteLibrary() {
      this.$store.dispatch('library/delete', this.library).then(() => {
        this.$store.commit('library/deleteFromList', this.library);
        this.$router.replace({ path: '/' });
      });
    },
  },
};
</script>
