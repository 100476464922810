const state = {
  isUploading: false,
  uploadProgress: null,
};

const getters = {
  isUploading(state) {
    return state.isUploading;
  },
  uploadProgress(state) {
    return state.uploadProgress;
  },
};

const actions = {
  setIsUploading(store, value) {
    store.commit('setIsUploading', value);
  },
  setUploadProgress(store, value) {
    store.commit('setUploadProgress', value);
  }
};

const mutations = {
  setIsUploading(state, value) {
    state.isUploading = value;
  },
  setUploadProgress(state, value) {
    state.uploadProgress = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
