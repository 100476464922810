var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page page--library-list" },
    [
      _c("page-title", {
        attrs: {
          title: "Video Libraries",
          showPlaylistButton: false,
          showUploadButton: false,
        },
      }),
      _c("div", { staticClass: "search" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchFor,
              expression: "searchFor",
            },
          ],
          attrs: { type: "text", placeholder: "Search..." },
          domProps: { value: _vm.searchFor },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.searchFor = $event.target.value
            },
          },
        }),
        _vm._m(0),
      ]),
      _c("div", { staticClass: "container" }, [
        _c("table", [
          _vm._m(1),
          _c(
            "tbody",
            _vm._l(_vm.filteredList, function (library) {
              return _c("tr", { key: library.id }, [
                _c(
                  "td",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/library/" + library.id } },
                      [
                        _c("i", { staticClass: "fa fa-play fa-lg" }),
                        _vm._v(" " + _vm._s(library.name) + " "),
                      ]
                    ),
                  ],
                  1
                ),
                _c("td", [_vm._v(_vm._s(library.video_count))]),
              ])
            }),
            0
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "right" }, [
      _c("i", { staticClass: "fa fa-search fa-lg" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "70%" } }, [_vm._v("Name")]),
        _c("th", { staticStyle: { width: "30%" } }, [_vm._v("Video Count")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }