<template>
  <modal
    name="create-playlist"
    class="create-playlist-modal"
    height="auto"
    @before-open="clearModal"
  >
    <div class="upper">
      <h2>Create Playlist</h2>
      <input
        v-model="playlistName"
        type="text"
        placeholder="Playlist name"
        @keyup.enter="createPlayList"
      />
    </div>
    <div class="buttons">
      <button class="button primary" @click="createPlayList">
        <pulse-loader
          v-if="isLoading"
          :loading="isLoading"
          color="#ffffff"
          size="6px"
        />
        <template v-else>Create</template>
      </button>
    </div>
  </modal>
</template>

<script>
import Playlist from '@/entities/Playlist';

export default {
  name: 'CreatePlaylistModal',
  data: () => ({
    isLoading: false,
    playlistName: '',
  }),
  computed: {
    libraryId() {
      return this.$route.params.libraryId;
    },
  },
  methods: {
    clearModal() {
      this.playlistName = '';
    },
    hideModal() {
      this.$modal.hide('create-playlist');
    },
    createPlayList() {
      if (!this.playlistName.length) {
        this.$toast.error('Enter a valid playlist name.');
      } else {
        this.isLoading = true;
        const playlist = new Playlist();
        playlist.name = this.playlistName;

        this.$store
          .dispatch('playlist/create', {
            libraryId: this.libraryId,
            entity: playlist,
          })
          .then(() => {
            this.hideModal();
            this.$router.replace({
              path: `/library/${this.libraryId}/playlists`,
            });
          })
          .finally((this.isLoading = false));
      }
    },
  },
};
</script>
