import('./assets/scss/styles.scss');

/* IMPORT SCRIPTS */
import Vue from 'vue';
import { router } from '@/router';
import { store } from '@/store';

import VModal from 'vue-js-modal';
Vue.use(VModal);

import vSelect from 'vue-select';
Vue.component('v-select', vSelect);
import "vue-select/dist/vue-select.css";

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.component('vue-toast', VueToast);
Vue.use(VueToast, {
  position: 'bottom-right',
  duration: 5000,
  dismissible: true,
});

import VTooltip from 'v-tooltip';
Vue.use(VTooltip);

import 'sweetalert2/src/sweetalert2.scss'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import App from './App.vue';

Vue.config.productionTip = false;

Vue.prototype.$roles = [
  { machine_name: 'superadmin', label: 'Adminisztrátor' },
  { machine_name: 'user', label: 'Felhasználó' },
];

Vue.prototype.$videoStatuses = {
  waiting: 0,
  in_progress: 1,
  completed: 2,
  failed: 3,
};

window.AppInstance = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
