<template>
  <div class="logo-component">
    <i class="icon logo" />
  </div>
</template>

<script>
export default{
  name: 'Logo',
}
</script>
