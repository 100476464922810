var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "colorpicker", class: _vm.cssClasses },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.colorValue,
            expression: "colorValue",
          },
        ],
        attrs: { type: "text", disabled: _vm.disabled, maxlength: "7" },
        domProps: { value: _vm.colorValue },
        on: {
          focus: _vm.showPicker,
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.colorValue = $event.target.value
            },
            _vm.updateFromInput,
          ],
        },
      }),
      _c("span", {
        staticClass: "current-color",
        style: "background-color: " + _vm.colorValue,
        on: { click: _vm.togglePicker },
      }),
      _vm.displayPicker
        ? _c("sketch-picker", {
            attrs: { value: _vm.colors, disableAlpha: true },
            on: { input: _vm.updateFromPicker },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }