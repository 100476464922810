import Playlist from '@/entities/Playlist';
import LibraryService from '@/services/Library';
import PlaylistService from '@/services/Playlist';

const state = {
  list: [],
};

const getters = {
  list(state) {
    return state.list;
  },

  get: (state) => (id) => {
    for (let item of state.list) {
      if (item.id === id) {
        return item;
      }
    }

    return null;
  },
};

const actions = {
  getList(store, libraryId) {
    store.commit('setList', []);
    return LibraryService.getPlaylists(libraryId).then((resp) => {
      store.commit('setList', resp);
      return resp;
    });
  },

  get(store, id) {
    return PlaylistService.get(id).then((resp) => {
      const responsedEntity = new Playlist(resp);

      if (!store.getters.get(responsedEntity.id)) {
        store.commit('addToList', responsedEntity);
      } else {
        store.commit('updateInList', responsedEntity);
      }

      return responsedEntity;
    });
  },

  create(store, { libraryId, entity }) {
    return PlaylistService.create(libraryId, entity)
      .then((resp) => {
        const responsedEntity = new Playlist(resp);
        store.commit('addToList', responsedEntity);

        return responsedEntity;
      });
  },

  update(store, entity) {
    return PlaylistService.update(entity)
      .then((resp) => {
        const responsedEntity = new Playlist(resp);
        store.commit('updateInList', responsedEntity);

        return responsedEntity;
      });
  },

  delete(store, entity) {
    return PlaylistService.delete(entity)
      .then(() => {
        store.commit('deleteFromList', entity);
      });
  },

  // Video actions
  addVideoToPlaylist(store, { playlist, video }) {
    return PlaylistService.addVideo(playlist.id, video.id)
      .then(() => {
        store.commit('addVideoToPlaylist', { playlist, video });
      });
  },

  deleteVideoFromPlaylist(store, { playlist, video }) {
    return PlaylistService.deleteVideo(playlist.id, video.id)
      .then(() => {
        store.commit('deleteVideoFromPlaylist', { playlist, video });
      });
  },

  deleteVideoFromPlaylistsByVideoId(store, videoId) {
    store.commit('deleteVideoFromPlaylistsByVideoId', videoId);
  }
};

const mutations = {
  setList(state, list) {
    state.list = [];

    for (let item of list) {
      if (item instanceof Playlist) {
        state.list.push(item);
      } else {
        state.list.push(new Playlist(item));
      }
    }
  },

  addToList(state, entity) {
    state.list.push(entity);
  },

  updateInList(state, entity) {
    for (let item of state.list) {
      if (item.id !== entity.id) {
        continue;
      }

      item.updateWith(entity);

      break;
    }
  },

  deleteFromList(state, entity) {
    for (var i = 0; i < state.list.length; i++) {
      if (state.list[i].id === entity.id) {
        state.list.splice(i, 1);
      }
    }
  },

  // Video mutations
  addVideoToPlaylist(state, { playlist, video }) {
    for (var i = 0; i < state.list.length; i++) {
      if (state.list[i].id === playlist.id) {
        state.list[i].videos.unshift(video);
      }
    }
  },

  deleteVideoFromPlaylist(state, { playlist, video }) {
    for (var i = 0; i < state.list.length; i++) {
      if (state.list[i].id === playlist.id) {
        for (var j = 0; j < state.list[i].videos.length; j++) {
          if (state.list[i].videos[j].id === video.id) {
            state.list[i].videos.splice(j, 1);
          }
        }
      }
    }
  },

  deleteVideoFromPlaylistsByVideoId(state, videoId) {
    for (var i = 0; i < state.list.length; i++) {
      for (var j = 0; j < state.list[i].videos.length; j++) {
        if (state.list[i].videos[j].id === videoId) {
          state.list[i].videos.splice(j, 1);
        }
      }
    }
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
