import Api from '@/services/Api';

export default {
  getList() {
    return Api()
      .get('/libraries')
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  get(id) {
    return Api()
      .get('/library/' + id)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  create(entity) {
    const data = {
      name: entity.name,
    };

    return Api()
      .post('/library', data)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  update(entity) {
    const data = {...entity};
    data.videos = null;
    data.stat = null;

    const formData = new FormData();
    this.buildFormData(formData, data);

    return Api()
      .post('/library/' + entity.id, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }

        return resp;
      });
  },

  delete(entity) {
    return Api()
      .delete('/library/' + entity.id)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  getPlaylists(id) {
    return Api()
      .get('/library/' + id + '/playlists')
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  getStat(id, from, to) {
    return Api()
      .get('/library/' + id + '/stat?from='+ from + '&to=' + to)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      let value = null;

      if (typeof data === 'boolean') {
        value = data ? 1 : 0;
      } else {
        value = data == null ? '' : data;
      }
  
      formData.append(parentKey, value);
    }
  }
};
