import Api from '@/services/Api';

export default {
  getList() {
    return Api()
      .get('/users')
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  get(id) {
    return Api()
      .get('/user/' + id)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  create(entity) {
    return Api()
      .post('/user', entity)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  update(entity) {
    return Api()
      .put('/user/' + entity.id, entity)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }

        return resp;
      });
  },

  delete(entity) {
    return Api()
      .delete('/user/' + entity.id)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
};
