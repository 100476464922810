import Api from '@/services/Api';

export default {

  get(id) {
    return Api()
      .get('/video/' + id)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  /**
   * @param {integer} libraryId 
   * @param {Video} entity 
   */
  create(libraryId, entity) {
    const data = {
      library_id: libraryId,
      filename: entity.filename,
      filesize: entity.filesize,
    };

    return Api()
      .post('/video', data)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  update(entity) {
    return Api()
      .put('/video/' + entity.id, JSON.stringify(entity))
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }

        return resp;
      });
  },

  getStat(id, from, to) {
    const params = {};
    if (from) {
      params.from = from;
    }
    if (to) {
      params.to = to
    }

    return Api()
      .get('/video/' + id + '/stat', { params: params })
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  delete(entity) {
    return Api()
      .delete('/video/' + entity.id)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  batchDelete(videoIds) {
    return Api()
      .put('/video/batch-delete', {video_ids: videoIds})
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
};
