export default class VideoVariation {

  id = null;
  name = '';
  thumbnail_url = '';
  width = null;
  height = null;
  video_bitrate = null;
  audio_bitrate = null;
  status = null;

  constructor (rawData) {
    if (Object.prototype.toString.call(rawData) !== '[object Object]') {
      return;
    }

    this.updateWith(rawData);
  }

  get isNew() {
    return this.id === null;
  }

  updateWith(newValues) {
    for (const [key, value] of Object.entries(newValues)) {
      if (typeof this[key] === 'undefined') {
        continue;
      }

      this[key] = value;
    }
  }
}
