<template>
  <div class="playlist-item">
    <router-link
      :to="`/library/${libraryId}/playlist/${playlist.id}`"
      class="item-wrapper"
    >
      <div class="icons">
        <i class="fad fa-folders"></i>
        <div v-if="videos" class="thumbnails">
          <div v-for="video in videos" :key="`${playlist.id}-${video.id}`">
            <img :src="video.thumbnail_url">
          </div>
        </div>
      </div>
      <div class="playlist-title">
        {{ playlist.name }}
      </div>
      <div class="number-of-videos">
        <i class="fad fa-play"></i>
        {{ videoNumber(playlist) }}
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'PlaylistItem',
  props: {
    playlist: {
      type: Object,
      required: true,
    }
  },
  computed: {
    libraryId() {
      return this.$route.params.libraryId;
    },
    videos() {
      return this.playlist.videos ? this.playlist.videos.slice(0, 3) : null;
    }
  },
  methods: {
    videoNumber(playlist) {
      return playlist.videos.length + ' ' + (playlist.videos.length == 1 ? 'video' : 'videos');
    },
  },
};
</script>
