<template>
  <div class="page page--stat">
    <page-title title="Statistics" />
    <div class="container">
      <div class="calendar-container">
        <input
          ref="dateInput"
          v-model="statDateFrom"
          type="text"
          class="date-input"
          @focus="onFocusDateInput"
          @blur="onBlurDateInput"
        />
        <p class="dash">-</p>
        <input
          ref="dateInput"
          v-model="statDateTo"
          type="text"
          class="date-input"
          @focus="onFocusDateInput"
          @blur="onBlurDateInput"
        />
        <functional-calendar
          v-show="calendarIsVisible"
          ref="calendar"
          v-model="calendarData"
          :configs="calendarConfigs"
          class="calendar"
          @dayClicked="clearDateRange()"
          @click.native="calendarStayInFocus()"
        />
        <div class="button primary" @click="getStat()">Filter</div>
      </div>

      <template v-if="isLoading">
        <div v-if="isLoading" class="loader" style="margin-top: 50px;">
          <i />
        </div>
      </template>

      <template v-else>
        <ul class="tab-navigation">
          <li :class="{ active: activeTab === 'general' }" @click="activeTab = 'general'">
            <i class="fad fa-chart-area" /> General
          </li>
          <li :class="{ active: activeTab === 'playlist' }" @click="activeTab = 'playlist'">
            <i class="fad fa-list" /> Playlist
          </li>
          <li :class="{ active: activeTab === 'video' }" @click="activeTab = 'video'">
            <i class="fad fa-photo-video" /> Video
          </li>
        </ul>

        <div v-show="activeTab === 'general'">
          <h4>View History</h4>
          <p>Total Views: <strong>{{ totalViews }} views</strong></p>
          <line-chart
            id="views-line"
            ref="views-line"
            :data="viewsChartData"
            xkey="date"
            ykeys='[ "value" ]'
            labels='[ "Total Views" ]'
            line-colors='[ "#fdd066" ]'
            grid="true"
            post-units="  views"
            hide-hover="auto"
            grid-text-family="Open Sans"
            grid-text-weight="600"
            :grid-text-size="13"
            grid-text-color="#515252"
            :behave-like-line="true"
            :point-size="4"
            :line-width="3"
            :resize="true"
            fill-ppacity="0.6"
          />
          <br/>
          <h4>Watch Time</h4>
          <p>Total Watch Time: <strong>{{ totalWatchTimes }} minutes</strong></p>
          <line-chart
            id="watch-time-line"
            ref="watch-time-line"
            :data="watchTimesChartData"
            xkey="date"
            ykeys='[ "value" ]'
            labels='[ "Watch time" ]'
            line-colors='[ "#fdd066" ]'
            grid="true"
            post-units="  minutes"
            hide-hover="auto"
            grid-text-family="Open Sans"
            grid-text-weight="600"
            :grid-text-size="13"
            grid-text-color="#515252"
            :behave-like-line="true"
            :point-size="4"
            :line-width="3"
            :resize="true"
            fill-ppacity="0.6"
          />
        </div>
        <div v-show="activeTab === 'playlist'">
          <table>
            <thead>
              <tr>
                <th style="width: 60%;">Name</th>
                <th style="width: 20%;">Views</th>
                <th style="width: 20%;">Watch Time</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="playlist in playlistStat" :key="`playlist-stat-${playlist.id}`">
                <td>
                  <router-link :to="'/library/' + libraryId + '/playlist/' + playlist.id">
                    {{ playlist.name }}
                  </router-link>
                </td>
                <td>{{ playlist.views }}</td>
                <td>{{ Math.round(playlist.watch_time / 60 / 60 * 100) / 100 }} hours</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-show="activeTab === 'video'">
          <div class="search">
            <input v-model="searchFor" type="text" placeholder="Search..." />
            <span class="right"> <i class="fa fa-search fa-lg" /></span>
          </div>
          <table>
            <thead>
              <tr>
                <th style="width: 60%;">Name</th>
                <th style="width: 20%;">Views</th>
                <th style="width: 20%;">Watch Time</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="video in filteredVideoStat" :key="`video-stat-${video.data.id}`">
                <td>{{ video.data.name }}</td>
                <td>{{ video.views }}</td>
                <td>{{ Math.round(video.watch_time / 60 * 100) / 100 }} minutes</td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';

import Raphael from 'raphael/raphael';
global.Raphael = Raphael;
import { LineChart } from 'vue-morris';
import { FunctionalCalendar } from 'vue-functional-calendar';
import DateMixin from '@/mixins/date';
export default {
  name: 'Statistics',
  mixins: [
    DateMixin,
  ],
  components: {
    PageTitle,
    LineChart,
    FunctionalCalendar,
  },
  data: () => ({
    isLoading: false,
    activeTab: 'general',

    searchFor: '',

    totalViews: 0,
    viewsChartData: [],
    totalWatchTimes: 0,
    watchTimesChartData: [],
    videoStat: [],
    playlistStat: [],

    calendarData: {},
    statDateFrom: '', // YYYY-MM-DD
    statDateTo: '', // YYYY-MM-DD
    calendarConfigs: {
      sundayStart: false,
      isDateRange: true,
      isDatePicker: false,
      dateFormat: 'yyyy-mm-dd'
    },
    calendarIsVisible: false,
    calendarHiddingTimer: null,
  }),
  computed: {
    library() {
      return this.$store.getters['library/get'](this.libraryId);
    },
    libraryId() {
      return this.$route.params.libraryId;
    },
    playlists() {
      return this.$store.getters['playlist/list'];
    },
    stat() {
      return this.library ? this.library.stat : null;
    },
    filteredVideoStat() {
      const searchQuery = this.searchFor.toLowerCase().trim();
        return this.videoStat.filter(video => {
          return video.data.name.toLowerCase().includes(searchQuery);
      });
    }
  },
  created() {
    let today = new Date();
    let fromDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

    this.statDateFrom = this.formatDateToYmd(fromDate);
    this.statDateTo = this.formatDateToYmd(today);

    this.calendarData.multipleDateRange = [{
      'start': this.statDateFrom,
      'end': this.statDateTo
    }];

    this.isLoading = true;

    this.getStat();
  },
  watch: {
    calendarData: {
      handler() {
        this.statDateFrom = this.calendarData.dateRange.start;
        this.statDateTo = this.calendarData.dateRange.end;
      },
      deep: true,
    },
    statDateFrom() {
      if (this.calendarData.dateRange) {
        this.calendarData.dateRange.start = this.statDateFrom;
      }
    },
    statDateTo() {
      if (this.calendarData.dateRange) {
        this.calendarData.dateRange.end = this.statDateTo;
      }
    }
  },
  methods: {
    clearDateRange() {
      this.$refs.dateInput.focus();
      this.calendarData.multipleDateRange = []
    },
    calendarStayInFocus() {
      this.$refs.dateInput.focus();
    },
    onBlurDateInput() {
      this.calendarHiddingTimer = setTimeout(() => {
        this.calendarIsVisible = false;
      }, 200);
    },
    onFocusDateInput() {
      if (this.calendarHiddingTimer !== null) {
        clearTimeout(this.calendarHiddingTimer);
        this.calendarHiddingTimer = null;
      }
      this.calendarIsVisible = true;
    },
    getStat() {
      this.isLoading = true;
      this.activeTab = 'general';

      this.$store.dispatch('library/getStat', {
        id: this.libraryId,
        from: this.formatDateToYmd(this.statDateFrom),
        to: this.formatDateToYmd(this.statDateTo)
      })
      .then((stat) => {
        this.setStat(stat);
      });
    },
    setStat(stat) {
      if (!stat || !stat.views || !stat.watch_times) {
        return null;
      }

      // Init playlist stats
      const videoPlaylistRelations = [];
      this.playlistStat = [];

      this.playlists.forEach(playlist => {
        this.playlistStat[playlist.id] = {
          id: playlist.id,
          name: playlist.name,
          views: 0,
          watch_time: 0,
        }

        playlist.videos.forEach(video => videoPlaylistRelations.push({videoId: video.id, playlistId: playlist.id}));
      });

      this.videoStat = [];
      const viewsStatArray = [];
      stat.views.forEach(item => {
        // Set overall views stat for charts
        if (typeof viewsStatArray[item.date] === 'undefined') {
          viewsStatArray[item.date] = 0;
        }
        viewsStatArray[item.date] += item.views;

        // Set video views stat for video toplist
        const videoId = item.content_id;
        if (typeof this.videoStat[videoId] === 'undefined') {
          const videoData = this.$store.getters['library/getVideo'](videoId);
          if (videoData) {
            this.videoStat[videoId] = {
              data: videoData,
              views: 0,
              watch_time: 0,
            }
          }
        }

        if (typeof this.videoStat[videoId] !== 'undefined') {
          this.videoStat[videoId].views += item.views;
        }

        // Set playlist views stat for playlist toplist
        videoPlaylistRelations.forEach(rel => {
          if (rel.videoId === videoId) {
            this.playlistStat[rel.playlistId].views += item.views;
          }
        });
      });

      const watchTimesStatArray = [];
      stat.watch_times.forEach(item => {
        // Set overall wath time stat for chars
        if (typeof watchTimesStatArray[item.date] === 'undefined') {
          watchTimesStatArray[item.date] = 0;
        }
        watchTimesStatArray[item.date] += item.value;

        // Set video views stat for video toplist
        const videoId = item.content_id;
        if (typeof this.videoStat[videoId] === 'undefined') {
          const videoData = this.$store.getters['library/getVideo'](videoId);
          if (videoData) {
            this.videoStat[videoId] = {
              data: videoData,
              views: 0,
              watch_time: 0,
            }
          }
        }

        if (typeof this.videoStat[videoId] !== 'undefined') {
          this.videoStat[videoId].watch_time += item.value;
        }

        // Set playlist views stat for playlist toplist
        videoPlaylistRelations.forEach(rel => {
          if (rel.videoId === videoId) {
            this.playlistStat[rel.playlistId].watch_time += item.value;
          }
        });
      });

      let dateFrom = new Date(this.statDateFrom.replace(/-/g, '/'));
      const dateTo = new Date(this.statDateTo.replace(/-/g, '/'));

      this.totalViews = 0;
      this.totalWatchTimes = 0;
      this.viewsChartData = [];
      this.watchTimesChartData = [];

      while (dateFrom <= dateTo) {
        const month = dateFrom.getMonth() + 1;
        const ymd = dateFrom.getFullYear() + '-' + (month < 10 ? '0' : '') + month + '-' +
          (dateFrom.getDate() < 10 ? '0' : '') + dateFrom.getDate();

        const views = typeof viewsStatArray[ymd] !== 'undefined' ? viewsStatArray[ymd] : 0;
        const watchTime = typeof watchTimesStatArray[ymd] !== 'undefined' ? Math.ceil(watchTimesStatArray[ymd] / 60) : 0;

        this.totalViews += views;
        this.totalWatchTimes += watchTime;

        this.viewsChartData.push({ date: ymd, value: views });
        this.watchTimesChartData.push({ date: ymd, value: watchTime });

        dateFrom = new Date(dateFrom.setDate(dateFrom.getDate() + 1));
      }

      // Trigger changes
      this.playlistStat = Object.values(this.playlistStat);
      this.videoStat = Object.values(this.videoStat);

      this.playlistStat = this.playlistStat.sort((a, b) => b.views - a.views);
      this.videoStat = this.videoStat.sort((a, b) => b.views - a.views);

      this.isLoading = false;
    },
  },
};
</script>
