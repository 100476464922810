<template>
  <div class="page page--user-list">
    <page-title
      title="Manage Users"
      :showPlaylistButton="false"
      :showUploadButton="false"
    />

    <router-link to="user/add" class="button primary add-user">
      <span class="icon"><i class="fa fa-user-plus" /></span>
      Add user
    </router-link>

    <div class="container" v-if="users.length">
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th style="width: 260px;">Manage</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td>
              <router-link :to="'/user/' + user.id">
                <i class="fa fa-user fa-lg" /> {{ user.name }}
              </router-link>
            </td>
            <td>{{ user.email }}</td>
            <td>
              <router-link :to="'/user/' + user.id" class="button secondary">
                <span class="icon"><i class="fa fa-edit" /></span> Edit
              </router-link>
              <button
                v-if="!userIsSuperadmin(user)"
                class="button secondary"
                @click="confirmDelete(user)"
              >
                <pulse-loader
                  v-if="isDeleting"
                  :loading="isDeleting"
                  color="#ffffff"
                  size="6px"
                />
                <template v-else>
                  <span class="icon"><i class="fa fa-times" /></span> Remove
                </template>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import UserMixin from '@/mixins/user';

export default {
  name: 'UserList',
  components: {
    PageTitle,
    PulseLoader,
  },
  mixins: [ UserMixin ],
  data: () => ({
    isDeleting: false,
  }),
  computed: {
    users() {
      return this.$store.getters['user/list'];
    },
  },
  created() {
    this.$store.dispatch('user/getList');
  },
  methods: {
    confirmDelete(user) {
      Swal.fire({
        title: 'Delete User?',
        text: 'Are you sure you want to delete the user?',
        icon: 'warning',
        showCancelButton: true,
        focusConfirm: false,
        reverseButtons: true,
        confirmButtonColor: '#BC4545',
        cancelButtonColor: '#B9B9B9',
        confirmButtonText: 'Delete User'
      }).then((result) => {
        if (result.isConfirmed) {
          this.delete(user);
        }
      })
    },
    delete(user) {
      this.isDeleting = true;

      this.$store.dispatch('user/delete', user)
        .then(() => {
          this.isDeleting = false;
          this.$toast.success('User successfully deleted');
        })
        .catch(e => {
          this.$toast.error('Failed to delete user.');
          console.error(e);
        })
        .finally(() => {
          this.isDeleting = false;
        });
    },
  }
};
</script>
