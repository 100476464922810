var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.classes,
      attrs: { "data-video-id": _vm.video.id },
      on: { click: _vm.onClick },
    },
    [
      _c("div", { staticClass: "cover" }, [
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.video.thumbnail_url,
              expression: "video.thumbnail_url",
            },
          ],
          attrs: { src: _vm.video.thumbnail_url },
        }),
        _vm.video.views
          ? _c("div", { staticClass: "views" }, [
              _vm._v(_vm._s(_vm.video.views) + " views"),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "details" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.video.name))]),
          _c(
            "div",
            { staticClass: "info" },
            [
              [
                _c("i", { staticClass: "fad fa-clock" }),
                _vm._v(" " + _vm._s(_vm.length) + " "),
                _c("i", { staticClass: "fa fa-video" }),
                _vm._v(" " + _vm._s(_vm.video.max_resolution) + " "),
              ],
            ],
            2
          ),
        ]),
        _c("div", { staticClass: "right" }, [
          _c(
            "div",
            {
              staticClass: "remove-video",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.removeVideo.apply(null, arguments)
                },
              },
            },
            [_c("i", { staticClass: "fa fa-times" })]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }