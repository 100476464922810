var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "video-modal",
      attrs: {
        name: "video-modal",
        width: "90%",
        "max-width": 1200,
        adaptive: true,
      },
      on: {
        opened: _vm.modalOpened,
        closed: function ($event) {
          _vm.previewIsInited = false
        },
      },
    },
    [
      _vm.isLoading
        ? _c("div", { staticClass: "loader" }, [_c("i")])
        : _vm.video
        ? [
            _c("div", { staticClass: "top" }, [
              _c("div", { staticClass: "left" }, [
                _c("div", { staticClass: "preview" }, [
                  _c("div", { staticClass: "input-label" }, [
                    _vm._v("Preview"),
                  ]),
                  _c("div", { staticClass: "video-preview" }, [
                    _vm.isProcessing
                      ? _c("div", { staticClass: "processing" }, [
                          _c("div", [
                            _c("i", { staticClass: "fas fa-cog fa-spin" }),
                            _vm._v(" Processing"),
                          ]),
                        ])
                      : _c("div", {
                          attrs: { id: `summit-player--${_vm.videoId}` },
                        }),
                  ]),
                  _c("div", { staticClass: "details" }, [
                    _c("div", { staticClass: "length" }, [
                      _c("i", { staticClass: "fad fa-clock" }),
                      _vm._v(" " + _vm._s(_vm.length) + " "),
                    ]),
                    _c("div", { staticClass: "filesize" }, [
                      _c("i", { staticClass: "fa fa-video" }),
                      _vm._v(" " + _vm._s(_vm.video.max_resolution) + " "),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "thumbnails" },
                  [
                    _c("div", { staticClass: "input-label" }, [
                      _vm._v("Thumbnails"),
                    ]),
                    _c("p", [
                      _vm._v(
                        " Select or upload your own thumbnail that will appear when the player is loaded before playback is started. "
                      ),
                    ]),
                    _c("thumbnail-list", {
                      attrs: { video: _vm.video },
                      on: { updated: _vm.updateThumbnail },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c("div", { staticClass: "input-label" }, [_vm._v("Title")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.video.name,
                        expression: "video.name",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.video.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.video, "name", $event.target.value)
                      },
                    },
                  }),
                  _c("div", { staticClass: "input-label" }, [
                    _vm._v("Description"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.video.description,
                        expression: "video.description",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.video.description },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.video, "description", $event.target.value)
                      },
                    },
                  }),
                  _c("div", { staticClass: "input-label" }, [_vm._v("Tags")]),
                  _c("vue-tags-input", {
                    attrs: { tags: _vm.video.tags },
                    on: {
                      "tags-changed": (newTags) => (_vm.video.tags = newTags),
                    },
                    model: {
                      value: _vm.tag,
                      callback: function ($$v) {
                        _vm.tag = $$v
                      },
                      expression: "tag",
                    },
                  }),
                  _c("div", { staticClass: "input-label" }, [
                    _vm._v("Video ID"),
                  ]),
                  _c("input-with-button", {
                    attrs: {
                      "input-disabled": "",
                      buttonLabel: "<i class='fa fa-copy'></i>",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.copyToClipboard(_vm.videoId)
                      },
                    },
                    model: {
                      value: _vm.videoId,
                      callback: function ($$v) {
                        _vm.videoId = $$v
                      },
                      expression: "videoId",
                    },
                  }),
                  _c("div", { staticClass: "input-label" }, [
                    _vm._v("HLS URL"),
                  ]),
                  _c("input-with-button", {
                    attrs: {
                      "input-disabled": "",
                      buttonLabel: "<i class='fa fa-copy'></i>",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.copyToClipboard(_vm.video.video_url)
                      },
                    },
                    model: {
                      value: _vm.video.video_url,
                      callback: function ($$v) {
                        _vm.$set(_vm.video, "video_url", $$v)
                      },
                      expression: "video.video_url",
                    },
                  }),
                  _c("div", { staticClass: "input-label" }, [
                    _vm._v("Thumbnail URL"),
                  ]),
                  _c("input-with-button", {
                    attrs: {
                      "input-disabled": "",
                      buttonLabel: "<i class='fa fa-copy'></i>",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.copyToClipboard(_vm.video.thumbnail_url)
                      },
                    },
                    model: {
                      value: _vm.video.thumbnail_url,
                      callback: function ($$v) {
                        _vm.$set(_vm.video, "thumbnail_url", $$v)
                      },
                      expression: "video.thumbnail_url",
                    },
                  }),
                  _c("div", { staticClass: "input-label" }, [
                    _vm._v("Add to Playlist:"),
                  ]),
                  _c("multiselect", {
                    attrs: {
                      options: _vm.playlists,
                      multiple: true,
                      "close-on-select": false,
                      "clear-on-select": false,
                      "preserve-search": true,
                      searchable: false,
                      showLabels: false,
                      placeholder: "Select playlists",
                      label: "name",
                      "track-by": "name",
                    },
                    on: { select: _vm.addPlaylist, remove: _vm.deletePlaylist },
                    model: {
                      value: _vm.video.playlists,
                      callback: function ($$v) {
                        _vm.$set(_vm.video, "playlists", $$v)
                      },
                      expression: "video.playlists",
                    },
                  }),
                  _c("div", { staticClass: "actions" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button primary",
                        on: {
                          click: function ($event) {
                            return _vm.saveVideo(true, false)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "icon" }, [
                          _c("i", { staticClass: "fa fa-save" }),
                        ]),
                        _vm._v(" Save "),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "button delete-video-button",
                        on: { click: _vm.confirmDelete },
                      },
                      [
                        _vm.isDeleting
                          ? _c("pulse-loader", {
                              attrs: {
                                loading: _vm.isDeleting,
                                color: "#ffffff",
                                size: "6px",
                              },
                            })
                          : [
                              _c("span", { staticClass: "icon" }, [
                                _c("i", { staticClass: "fa fa-times" }),
                              ]),
                              _vm._v(" Delete Video "),
                            ],
                      ],
                      2
                    ),
                  ]),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "bottom" }, [
              _c("ul", { staticClass: "tab-navigation" }, [
                _c(
                  "li",
                  {
                    class: { active: _vm.activeTab === "embed" },
                    on: {
                      click: function ($event) {
                        _vm.activeTab = "embed"
                      },
                    },
                  },
                  [_c("i", { staticClass: "fad fa-code" }), _vm._v(" Embed ")]
                ),
                _c(
                  "li",
                  {
                    class: { active: _vm.activeTab === "statistics" },
                    on: {
                      click: function ($event) {
                        _vm.activeTab = "statistics"
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fad fa-chart-area" }),
                    _vm._v(" Statistics "),
                  ]
                ),
                _c(
                  "li",
                  {
                    class: { active: _vm.activeTab === "advertising" },
                    on: {
                      click: function ($event) {
                        _vm.activeTab = "advertising"
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fad fa-ad" }),
                    _vm._v(" Advertising "),
                  ]
                ),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === "embed",
                      expression: "activeTab === 'embed'",
                    },
                  ],
                  staticClass: "embed-tab",
                },
                [
                  _c("p", [
                    _vm._v(
                      "Use this code inside of your website HTML to display the player."
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "switches" },
                    [
                      _c("on-off", {
                        on: { change: _vm.checkAutoplay },
                        model: {
                          value: _vm.video.autoplay,
                          callback: function ($$v) {
                            _vm.$set(_vm.video, "autoplay", $$v)
                          },
                          expression: "video.autoplay",
                        },
                      }),
                      _vm._v(" Autoplay "),
                      _c("on-off", {
                        on: { change: _vm.checkMuted },
                        model: {
                          value: _vm.video.muted,
                          callback: function ($$v) {
                            _vm.$set(_vm.video, "muted", $$v)
                          },
                          expression: "video.muted",
                        },
                      }),
                      _vm._v(" Muted "),
                      _c("on-off", {
                        on: { change: _vm.checkLoop },
                        model: {
                          value: _vm.video.loop,
                          callback: function ($$v) {
                            _vm.$set(_vm.video, "loop", $$v)
                          },
                          expression: "video.loop",
                        },
                      }),
                      _vm._v(" Loop "),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "embed-code-container" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showGenerateEmbedCodeButton,
                            expression: "showGenerateEmbedCodeButton",
                          },
                        ],
                        staticClass: "embed-code-layer",
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "button",
                            on: {
                              click: function ($event) {
                                _vm.showGenerateEmbedCodeButton = false
                              },
                            },
                          },
                          [_vm._v("Generate Embed Code")]
                        ),
                        _c("div", [
                          _vm._v(
                            " You can generate embed code with the settings above, and you can embed it to any website "
                          ),
                        ]),
                      ]
                    ),
                    _c("textarea", {
                      attrs: { readonly: "" },
                      domProps: { innerHTML: _vm._s(_vm.embedCode) },
                      on: {
                        click: function ($event) {
                          return _vm.copyToClipboard(_vm.embedCode)
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _vm.activeTab === "statistics"
                ? _c(
                    "div",
                    { staticClass: "stat-tab" },
                    [
                      _vm.statIsLoading
                        ? _c("div", { staticClass: "loader" }, [_c("i")])
                        : [
                            _c(
                              "div",
                              { staticClass: "calendar-container" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.statDateFrom,
                                      expression: "statDateFrom",
                                    },
                                  ],
                                  ref: "dateInput",
                                  staticClass: "date-input",
                                  attrs: { type: "text" },
                                  domProps: { value: _vm.statDateFrom },
                                  on: {
                                    focus: _vm.onFocusDateInput,
                                    blur: _vm.onBlurDateInput,
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.statDateFrom = $event.target.value
                                    },
                                  },
                                }),
                                _c("p", { staticClass: "dash" }, [_vm._v("-")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.statDateTo,
                                      expression: "statDateTo",
                                    },
                                  ],
                                  ref: "dateInput",
                                  staticClass: "date-input",
                                  attrs: { type: "text" },
                                  domProps: { value: _vm.statDateTo },
                                  on: {
                                    focus: _vm.onFocusDateInput,
                                    blur: _vm.onBlurDateInput,
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.statDateTo = $event.target.value
                                    },
                                  },
                                }),
                                _c("functional-calendar", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.calendarIsVisible,
                                      expression: "calendarIsVisible",
                                    },
                                  ],
                                  ref: "calendar",
                                  staticClass: "calendar",
                                  attrs: { configs: _vm.calendarConfigs },
                                  on: {
                                    dayClicked: function ($event) {
                                      return _vm.clearDateRange()
                                    },
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.calendarStayInFocus()
                                    },
                                  },
                                  model: {
                                    value: _vm.calendarData,
                                    callback: function ($$v) {
                                      _vm.calendarData = $$v
                                    },
                                    expression: "calendarData",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "button primary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getStat()
                                      },
                                    },
                                  },
                                  [_vm._v("Filter")]
                                ),
                              ],
                              1
                            ),
                            _c("h4", [_vm._v("View History")]),
                            _c("p", [
                              _vm._v("Total Views: "),
                              _c("strong", [
                                _vm._v(_vm._s(_vm.totalViews) + " views"),
                              ]),
                            ]),
                            _c("line-chart", {
                              ref: "views-line",
                              attrs: {
                                id: "views-line",
                                data: _vm.viewsChartData,
                                xkey: "date",
                                ykeys: '[ "value" ]',
                                labels: '[ "Total Views" ]',
                                "line-colors": '[ "#fdd066" ]',
                                grid: "true",
                                "post-units": "  views",
                                "hide-hover": "auto",
                                "grid-text-family": "Open Sans",
                                "grid-text-weight": "600",
                                "grid-text-size": 13,
                                "grid-text-color": "#515252",
                                "behave-like-line": true,
                                "point-size": 4,
                                "line-width": 3,
                                resize: true,
                                "fill-ppacity": "0.6",
                              },
                            }),
                            _c("br"),
                            _c("h4", [_vm._v("Watch Time")]),
                            _c("p", [
                              _vm._v("Total Watch Time: "),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.totalWatchTimes) + " minutes"
                                ),
                              ]),
                            ]),
                            _c("line-chart", {
                              ref: "watch-time-line",
                              attrs: {
                                id: "watch-time-line",
                                data: _vm.watchTimesChartData,
                                xkey: "date",
                                ykeys: '[ "value" ]',
                                labels: '[ "Watch time" ]',
                                "line-colors": '[ "#fdd066" ]',
                                grid: "true",
                                "post-units": "  minutes",
                                "hide-hover": "auto",
                                "grid-text-family": "Open Sans",
                                "grid-text-weight": "600",
                                "grid-text-size": 13,
                                "grid-text-color": "#515252",
                                "behave-like-line": true,
                                "point-size": 4,
                                "line-width": 3,
                                resize: true,
                                "fill-ppacity": "0.6",
                              },
                            }),
                          ],
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === "advertising",
                      expression: "activeTab === 'advertising'",
                    },
                  ],
                  staticClass: "advertising-tab",
                },
                [
                  _c(
                    "div",
                    { staticClass: "disable-ads-container" },
                    [
                      _c("on-off", {
                        model: {
                          value: _vm.video.disable_ads,
                          callback: function ($$v) {
                            _vm.$set(_vm.video, "disable_ads", $$v)
                          },
                          expression: "video.disable_ads",
                        },
                      }),
                      _c("p", [_vm._v("Disable ads")]),
                    ],
                    1
                  ),
                  _c("p", [
                    _vm._v(
                      "Here you can enter a unique VAST Tag URL for the video"
                    ),
                  ]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.video.vast_tag_url,
                        expression: "video.vast_tag_url",
                      },
                    ],
                    domProps: { value: _vm.video.vast_tag_url },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.video, "vast_tag_url", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button primary",
                      on: {
                        click: function ($event) {
                          return _vm.saveVideo(true, true)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "icon" }, [
                        _c("i", { staticClass: "fa fa-save" }),
                      ]),
                      _vm._v(" Save "),
                    ]
                  ),
                ]
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }