<template>
  <modal
    name="video-modal"
    class="video-modal"
    width="90%"
    :max-width="1200"
    :adaptive="true"
    @opened="modalOpened"
    @closed="previewIsInited = false"
  >
    <div v-if="isLoading" class="loader">
      <i />
    </div>
    <template v-else-if="video">
      <div class="top">
        <div class="left">
          <div class="preview">
            <div class="input-label">Preview</div>
            <div class="video-preview">
                <div v-if="isProcessing" class="processing">
                  <div><i class="fas fa-cog fa-spin"></i> Processing</div>
                </div>
                <div v-else :id="`summit-player--${videoId}`"></div>
            </div>
            <div class="details">
              <div class="length">
                <i class="fad fa-clock"></i> {{ length }}
              </div>
              <div class="filesize">
                <i class="fa fa-video"></i> {{ video.max_resolution }}
              </div>
            </div>
          </div>
          <div class="thumbnails">
            <div class="input-label">Thumbnails</div>
            <p>
              Select or upload your own thumbnail that will appear when the player is loaded before playback is started.
            </p>
            <thumbnail-list :video="video" @updated="updateThumbnail" />
          </div>
        </div>
        <div class="right">
          <div class="input-label">Title</div>
          <input
            v-model="video.name"
            type="text"
          />
          <div class="input-label">Description</div>
          <input
            v-model="video.description"
            type="text"
          />
          <div class="input-label">Tags</div>
          <vue-tags-input
            v-model="tag"
            :tags="video.tags"
            @tags-changed="newTags => video.tags = newTags"
          />
          <div class="input-label">Video ID</div>
          <input-with-button
            v-model="videoId"
            input-disabled
            buttonLabel="<i class='fa fa-copy'></i>"
            @click="copyToClipboard(videoId)"
          />
          <div class="input-label">HLS URL</div>
          <input-with-button
            v-model="video.video_url"
            input-disabled
            buttonLabel="<i class='fa fa-copy'></i>"
            @click="copyToClipboard(video.video_url)"
          />
          <div class="input-label">Thumbnail URL</div>
          <input-with-button
            v-model="video.thumbnail_url"
            input-disabled
            buttonLabel="<i class='fa fa-copy'></i>"
            @click="copyToClipboard(video.thumbnail_url)"
          />
          <div class="input-label">Add to Playlist:</div>
          <multiselect
            v-model="video.playlists"
            :options="playlists"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :searchable="false"
            :showLabels="false"
            placeholder="Select playlists"
            label="name"
            track-by="name"
            @select="addPlaylist"
            @remove="deletePlaylist"
          />
          <div class="actions">
            <button class="button primary" @click="saveVideo(true, false)">
              <span class="icon"><i class="fa fa-save" /></span> Save
            </button>
            <button class="button delete-video-button" @click="confirmDelete">
              <pulse-loader
                v-if="isDeleting"
                :loading="isDeleting"
                color="#ffffff"
                size="6px"
              />
              <template v-else>
                <span class="icon"><i class="fa fa-times" /></span> Delete Video
              </template>
            </button>
          </div>
        </div>
      </div>
      <div class="bottom">
        <ul class="tab-navigation">
          <li :class="{ active: activeTab === 'embed' }" @click="activeTab = 'embed'">
            <i class="fad fa-code" /> Embed
          </li>
          <li :class="{ active: activeTab === 'statistics' }" @click="activeTab = 'statistics'">
            <i class="fad fa-chart-area" /> Statistics
          </li>
          <li :class="{ active: activeTab === 'advertising' }" @click="activeTab = 'advertising'">
            <i class="fad fa-ad" /> Advertising
          </li>
        </ul>
        <div v-show="activeTab === 'embed'" class="embed-tab">
          <p>Use this code inside of your website HTML to display the player.</p>
          <div class="switches">
            <on-off v-model="video.autoplay" @change="checkAutoplay" /> Autoplay
            <on-off v-model="video.muted" @change="checkMuted" /> Muted
            <on-off v-model="video.loop" @change="checkLoop" /> Loop
          </div>
          <div class="embed-code-container">
            <div v-show="showGenerateEmbedCodeButton" class="embed-code-layer">
              <button class="button" @click="showGenerateEmbedCodeButton = false">Generate Embed Code</button>
              <div>
                You can generate embed code with the settings above, and you can embed it to any website
              </div>
            </div>
            <textarea readonly v-html="embedCode" @click="copyToClipboard(embedCode)" />
          </div>
        </div>
        <div v-if="activeTab === 'statistics'" class="stat-tab">
          <div v-if="statIsLoading" class="loader">
            <i />
          </div>
          <template v-else>
            <div class="calendar-container">
              <input
                ref="dateInput"
                v-model="statDateFrom"
                type="text"
                class="date-input"
                @focus="onFocusDateInput"
                @blur="onBlurDateInput"
              />
              <p class="dash">-</p>
              <input
                ref="dateInput"
                v-model="statDateTo"
                type="text"
                class="date-input"
                @focus="onFocusDateInput"
                @blur="onBlurDateInput"
              />

              <functional-calendar
                v-show="calendarIsVisible"
                ref="calendar"
                v-model="calendarData"
                :configs="calendarConfigs"
                class="calendar"
                @dayClicked="clearDateRange()"
                @click.native="calendarStayInFocus()"
              />
              <div class="button primary" @click="getStat()">Filter</div>
            </div>
            <h4>View History</h4>
            <p>Total Views: <strong>{{ totalViews }} views</strong></p>
            <line-chart
              id="views-line"
              ref="views-line"
              :data="viewsChartData"
              xkey="date"
              ykeys='[ "value" ]'
              labels='[ "Total Views" ]'
              line-colors='[ "#fdd066" ]'
              grid="true"
              post-units="  views"
              hide-hover="auto"
              grid-text-family="Open Sans"
              grid-text-weight="600"
              :grid-text-size="13"
              grid-text-color="#515252"
              :behave-like-line="true"
              :point-size="4"
              :line-width="3"
              :resize="true"
              fill-ppacity="0.6"
            />
            <br/>
            <h4>Watch Time</h4>
            <p>Total Watch Time: <strong>{{ totalWatchTimes }} minutes</strong></p>
            <line-chart
              id="watch-time-line"
              ref="watch-time-line"
              :data="watchTimesChartData"
              xkey="date"
              ykeys='[ "value" ]'
              labels='[ "Watch time" ]'
              line-colors='[ "#fdd066" ]'
              grid="true"
              post-units="  minutes"
              hide-hover="auto"
              grid-text-family="Open Sans"
              grid-text-weight="600"
              :grid-text-size="13"
              grid-text-color="#515252"
              :behave-like-line="true"
              :point-size="4"
              :line-width="3"
              :resize="true"
              fill-ppacity="0.6"
            />
          </template>
        </div>
        <div v-show="activeTab === 'advertising'" class="advertising-tab">
          <div class="disable-ads-container">
            <on-off v-model="video.disable_ads" />
            <p>Disable ads</p>
          </div>
          <p>Here you can enter a unique VAST Tag URL for the video</p>
          <textarea v-model="video.vast_tag_url" />
          <button class="button primary" @click="saveVideo(true, true)">
            <span class="icon">
              <i class="fa fa-save"></i>
            </span>
            Save
          </button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import InputWithButton from '@/components/InputWithButton';
import OnOff from '@/components/OnOff.vue';
import ThumbnailList from '@/components/ThumbnailList';
import Video from '@/entities/Video';
import VideoService from '@/services/Video';
import DateMixin from '@/mixins/date';
import VideoMixin from '@/mixins/video';

import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import Multiselect from 'vue-multiselect';

import VueTagsInput from '@johmun/vue-tags-input';
import Raphael from 'raphael/raphael';
global.Raphael = Raphael;
import { LineChart } from 'vue-morris';
import { FunctionalCalendar } from 'vue-functional-calendar';

export default {
  name: 'VideoUploadModal',
  mixins: [
    DateMixin,
    VideoMixin
  ],
  components: {
    InputWithButton,
    OnOff,
    ThumbnailList,
    LineChart,
    Multiselect,
    PulseLoader,
    FunctionalCalendar,
    VueTagsInput
  },
  props: {
    videoId: {
      type: String,
      required: false,
      default: null
    },
    isProcessing: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    previewIsInited: false,
    isLoading: true,
    isDeleting: false,
    video: null,
    activeTab: 'embed',
    showGenerateEmbedCodeButton: false,
    originalAutoplay: null,
    originalMuted: null,
    originalLoop: null,
    tag: '',

    // Video stat
    stat: null,
    statDateFrom: '', // YYYY-MM-DD
    statDateTo: '', // YYYY-MM-DD
    statIsLoading: false,
    totalViews: 0,
    viewsChartData: [],
    totalWatchTimes: 0,
    watchTimesChartData: [],
    calendarData:{},
    calendarConfigs: {
      sundayStart: false,
      isDateRange: true,
      isDatePicker: false,
      dateFormat: "yyyy-mm-dd"
    },
    calendarIsVisible: false,
    calendarHiddingTimer: null,
  }),
  created() {
    let today = new Date();
    let fromDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    this.calendarData.multipleDateRange=[{
      "start": this.formatDateToYmd(fromDate), 
      "end": this.formatDateToYmd(today)
    }]
  },
  computed: {
    libraryId() {
      return this.$route.params.libraryId;
    },
    library() {
      return this.$store.getters['library/get'](this.libraryId);
    },
    playlists() {
      return this.$store.getters['playlist/list'].map(item => ({ id: item.id, name: item.name }));
    },
    playerUrl() {
      return process.env.VUE_APP_PLAYER_URL;
    },
    embedCode() {
      const props = [];
      props.push('type: \'video\'');
      if (this.video.autoplay) {
        props.push('autoplay: true');
      }
      if (this.video.muted) {
        props.push('muted: true');
      }
      if (this.video.loop) {
        props.push('loop: true');
      }

      return '<div id="summit-player--' + this.videoId + '"></div>' + "\r\n" +
        '<script>' + "\r\n" +
        'window.summitplayer = window.summitplayer || { players: {} };' + "\r\n" +
        'window.summitplayer.players[\'' + this.videoId + '\'] = { ' + props.join(', ') + ' };' + "\r\n" +
        '</sc' + 'ript>' + "\r\n" +
        '<script src="' + this.playerUrl + '/embed.js" defer></sc' + 'ript>';
    },
  },
  watch: {
    videoId() {
      this.resetStat();
      this.fetch();
    },
    activeTab() {
      if (this.activeTab === 'statistics' && this.stat === null) {
        this.getStat();
      }
    },
    calendarData: {
      handler() {
        this.statDateFrom = this.calendarData.dateRange.start;
        this.statDateTo = this.calendarData.dateRange.end;
      },
      deep: true,
    },
    statDateFrom() {
      if (this.calendarData.dateRange) {
        this.calendarData.dateRange.start = this.statDateFrom
      }
    },
    statDateTo() {
      if (this.calendarData.dateRange) {
        this.calendarData.dateRange.end = this.statDateTo
      }
    }
  },
  methods: {
    clearDateRange() {
      this.$refs.dateInput.focus();
      this.calendarData.multipleDateRange = []
    },
    calendarStayInFocus() {
      this.$refs.dateInput.focus();
    },
    onBlurDateInput() {
      this.calendarHiddingTimer = setTimeout(() => {
        this.calendarIsVisible = false;
      }, 200);
    },
    onFocusDateInput() {
      if (this.calendarHiddingTimer !== null) {
        clearTimeout(this.calendarHiddingTimer);
        this.calendarHiddingTimer = null;
      }
      this.calendarIsVisible = true;
    },
    resetStat() {
      this.stat = null;

      const today = new Date();
      const dateFrom = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

      this.statDateFrom = this.formatDateToYmd(dateFrom);
      this.statDateTo = this.formatDateToYmd(today);
    },
    fetch() {
      this.isLoading = true;

      VideoService.get(this.videoId)
        .then((resp) => {
          this.video = new Video(resp);
          this.isLoading = false;
          this.initPreview();

          this.originalAutoplay = this.video.autoplay;
          this.originalMuted = this.video.muted;
          this.originalLoop = this.video.loop;
        });
    },
    initPreview(forcePreview) {
      if (!this.previewIsInited || forcePreview) {
        if (typeof window.summitplayer === 'undefined') {
          window.summitplayer = { players: {}, buildPlayers() {} };

          const script = document.createElement('script');
          script.setAttribute('src', this.playerUrl + '/embed.js');
          document.head.appendChild(script);
        }
        else {
          window.summitplayer.players = {};
        }

        window.summitplayer.players[this.videoId] = {
          type: 'video',
          autoplay: this.video.autoplay,
          muted: this.video.muted,
          loop: this.video.loop
        };

        setTimeout(() => window.summitplayer.buildPlayers(), 500);

        this.previewIsInited = true;
      }
    },
    confirmDelete() {
      Swal.fire({
        title: 'Delete video?',
        text: 'Are you sure you want to delete the video?',
        icon: 'warning',
        showCancelButton: true,
        focusConfirm: false,
        reverseButtons: true,
        confirmButtonColor: '#BC4545',
        cancelButtonColor: '#B9B9B9',
        confirmButtonText: 'Delete Video'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteVideo();
        }
      })
    },
    saveVideo(showToast, refreshPreview) {
      if (!this.video.name) {
        this.$toast.error('Video title cannot be empty');
        return null;
      }

      VideoService.update(this.video)
        .then(() => {
          if (showToast) {
            this.$toast.clear();
            this.$toast.success('The video was updated');
          }
          if (refreshPreview) {
            this.initPreview(true);
          }
          this.updateVideoInLibrary();
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
    updateThumbnail() {
      this.updateVideoInLibrary();
      this.initPreview(true);
    },
    updateVideoInLibrary() {
      this.$store.dispatch('library/updateVideoInLibrary', { library: this.library, video: this.video });
    },
    deleteVideo() {
      this.isDeleting = true;

      VideoService.delete(this.video).then(() => {
        this.$store.dispatch('library/deleteVideoFromLibrary', { library: this.library, video: this.video });

        this.isDeleting = false;
        this.$modal.hide('video-modal');
        this.$toast.success('Video deleted');
      });
    },
    async copyToClipboard(text) {
      await navigator.clipboard.writeText(text);
      this.$toast.clear();
      this.$toast.success('Copied to clipboard', { duration: 2000 });
    },
    checkAutoplay() {
      if (this.video.autoplay) {
        this.video.muted = true;
      }
      this.saveVideo(false, true);
      this.checkSwitchChange();
    },
    checkMuted() {
      if (!this.video.muted) {
        this.video.autoplay = false;
      }
      this.saveVideo(false, true);
      this.checkSwitchChange();
    },
    checkLoop() {
      this.saveVideo(false, true);
      this.checkSwitchChange();
    },
    checkSwitchChange() {
      const changed = this.video.autoplay !== this.originalAutoplay || this.video.muted !== this.originalMuted
        || this.video.loop !== this.originalLoop;

      if (changed) {
        this.originalAutoplay = this.video.autoplay;
        this.originalMuted = this.video.muted;
        this.originalLoop = this.video.loop;
      }

      this.showGenerateEmbedCodeButton = changed;
    },
    async getStat() {
      if (this.statIsLoading) {
        return null;
      }

      this.statIsLoading = true;

      await VideoService.getStat(this.videoId, this.statDateFrom, this.statDateTo)
        .then((resp) => {
          if (resp.stat) {
            this.stat = resp.stat
          }
        })
        .finally(() => this.statIsLoading = false);

      if (!this.stat.views || !this.stat.watch_times) {
        return null;
      }

      const viewsStatArray = [];
      this.stat.views.forEach(item => {
        if (typeof viewsStatArray[item.date] === 'undefined') {
          viewsStatArray[item.date] = 0;
        }

        viewsStatArray[item.date] += item.views;
      });

      const watchTimesStatArray = [];
      this.stat.watch_times.forEach(item => {
        if (typeof watchTimesStatArray[item.date] === 'undefined') {
          watchTimesStatArray[item.date] = 0;
        }
        watchTimesStatArray[item.date] += item.value;
      });

      let dateFrom = new Date(this.statDateFrom);
      const dateTo = new Date(this.statDateTo);

      this.totalViews = 0;
      this.totalWatchTimes = 0;
      this.viewsChartData = [];
      this.watchTimesChartData = [];

      while (dateFrom <= dateTo) {
        const month = dateFrom.getMonth() + 1;
        const ymd = dateFrom.getFullYear() + '-' + (month < 10 ? '0' : '') + month + '-' +
          (dateFrom.getDate() < 10 ? '0' : '') + dateFrom.getDate();

        const views = typeof viewsStatArray[ymd] !== 'undefined' ? viewsStatArray[ymd] : 0;
        const watchTime = typeof watchTimesStatArray[ymd] !== 'undefined' ? Math.ceil(watchTimesStatArray[ymd] / 60) : 0;

        this.totalViews += views;
        this.totalWatchTimes += watchTime;

        this.viewsChartData.push({ date: ymd, value: views });
        this.watchTimesChartData.push({ date: ymd, value: watchTime });

        dateFrom = new Date(dateFrom.setDate(dateFrom.getDate() + 1));
      }
    },
    modalOpened() {
      if (this.video && !this.isProcessing) {
        this.initPreview();
      }
    },
    addPlaylist(playlist) {
      this.$store.dispatch('playlist/addVideoToPlaylist', { playlist, video: this.video })
        .then(() => {
          this.$toast.clear();
          this.$toast.success('Video added to playlist');
        })
        .catch(() => {
          this.$toast.clear();
          this.$toast.success('Error while adding to playlist');
        });
    },
    deletePlaylist(playlist) {
      this.$store.dispatch('playlist/deleteVideoFromPlaylist', { playlist, video: this.video })
        .then(() => {
          this.$toast.clear();
          this.$toast.success('Video removed from playlist');
        })
        .catch(() => {
          this.$toast.clear();
          this.$toast.success('Error while removing from playlist');
        });
    },
  }
}
</script>
