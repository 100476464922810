var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sidebar" }, [
    _c("div", { staticClass: "title" }, [_vm._v("Libraries")]),
    _c(
      "div",
      { staticClass: "functions" },
      [
        _c("router-link", { attrs: { to: "/" } }, [
          _c("i", { staticClass: "fa fa-fw fa-photo-video" }),
          _vm._v(" Manage Libraries "),
        ]),
        _c("router-link", { attrs: { to: "/library/add" } }, [
          _c("i", { staticClass: "fa fa-fw fa-plus" }),
          _vm._v(" Add Video Library "),
        ]),
        _vm.currentUserIsSuperadmin
          ? _c("router-link", { attrs: { to: "/users" } }, [
              _c("i", { staticClass: "far fa-fw fa-users-crown" }),
              _vm._v(" Manage Users "),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }