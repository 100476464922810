<template>
  <div class="page page--gemius">
    <page-title title="Advertising" />
    <div class="container">
      <h2>Gemius ID:</h2>
        <p>
          Place your Gemius ID below here.
        </p>
        <p>
          <input-with-button
            v-model="library.gemius_id"
            buttonLabel="<i class='fa fa-save'></i> Save"
            @click="save"
          />
        </p>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import InputWithButton from '@/components/InputWithButton';

export default {
  name: 'Gemius',
  components: {
    PageTitle,
    InputWithButton
  },
  computed: {
      libraryId() {
      return this.$route.params.libraryId;
    },
    library() {
      return this.$store.getters['library/get'](this.libraryId)
    }
  },
  methods: {
    save() {
      this.$store.dispatch('library/save', this.library)
        .then(() => {
          this.$toast.success('ID was updated');
        })
        .catch(() =>
          this.$toast.error('Invalid ID!')
        );
    }
  },
};
</script>
