<template>
  <div class="page page--manage-videos">
    <page-title title="Manage Videos" />
    <div class="container">
      <div class="actions">
        <div class="batch-operations">
          <v-select
            v-model="batchOperation"
            :options="batchOperationOptions"
            :clearable="false"
            :searchable="false"
          >
            <template #open-indicator="{ attributes }">
              <i v-bind="attributes" class="icon dropdown-arrow"></i>
            </template>
          </v-select>
          <button
            v-show="isBatchSelecting"
            class="button"
            :class="{'disabled': !selectedVideoIds.length, 'disabled': isLoading}"
            @click="doBatchOperation"
          >
            Apply
          </button>
        </div>
        <div class="search">
          <input v-model="searchFor" type="text" placeholder="Search..." />
          <span class="right"> <i class="fa fa-search fa-lg" /></span>
        </div>
      </div>

      <div class="item-list-container">
        <div v-show="isLoading" class="loader">
          <i />
        </div>
        <div class="item-list">
          <video-item
            v-for="video in filteredVideos"
            :key="video.id"
            :video="video"
            :is-batch-selecting="isBatchSelecting"
            @select="selectVideo"
            @click="openVideoModal"
          />
        </div>
      </div>
    </div>
    <video-modal :video-id="videoId" :is-processing="this.isProcessingVideo"/>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import VideoItem from '@/components/VideoItem.vue';
import VideoModal from '@/components/VideoModal.vue';
import VideoService from '@/services/Video';

export default {
  name: 'ManageVideos',
  components: {
    PageTitle,
    VideoItem,
    VideoModal,
  },
  data: () => ({
    batchOperation: 'Batch operations',
    batchOperationOptions: [
      'Batch operations',
      'Delete selected videos',
    ],
    selectedVideoIds: [],
    searchFor: '',
    isLoading: false,
    videoId: null,
    interval: null,
  }),
  computed: {
    libraryId() {
      return this.$route.params.libraryId;
    },
    library() {
      return this.$store.getters['library/get'](this.libraryId);
    },
    videos() {
      return this.library && this.library.videos.length ? this.library.videos : [];
    },
    filteredVideos() {
      if (!this.searchFor) {
        return this.videos;
      }
      const term = this.searchFor.toLowerCase();

      return this.videos.filter(item => item.name.toLowerCase().includes(term));
    },
    isProcessingVideo() {
      if (!this.library || !this.videos) {
        return false;
      }

      return this.videos.find(item => item.status === this.$videoStatuses.in_progress) ? true : false;
    },
    isBatchSelecting() {
      return this.batchOperation !== 'Batch operations';
    }
  },
  watch: {
    isProcessingVideo(newVal, oldVal) {
      if (newVal === true && !this.interval) {
        this.interval = setInterval(() => {
          this.$store.dispatch('library/get', this.libraryId);
        }, 10 * 1000);
      }
      else if (oldVal === true && newVal === false) {
        clearInterval(this.interval);
        this.interval = null;
      }
    }
  },
  mounted() {
    if (this.isProcessingVideo && !this.interval) {
      this.interval = setInterval(() => {
        this.$store.dispatch('library/get', this.libraryId);
      }, 10 * 1000);
    }
  },
  methods: {
    openVideoModal(videoId) {
      this.videoId = videoId;
      this.$modal.show('video-modal');
    },
    selectVideo(videoId) {
      const index = this.selectedVideoIds.indexOf(videoId)
      if (index === -1) {
        this.selectedVideoIds.push(videoId);
      } else {
        this.selectedVideoIds.splice(index, 1);
      }
    },
    doBatchOperation() {
      if (!this.selectedVideoIds || this.isLoading) {
        return null;
      }

      if (this.batchOperation === 'Delete selected videos') {
        this.isLoading = true
        VideoService.batchDelete(this.selectedVideoIds)
          .then(() => {
            this.selectedVideoIds.forEach(videoId => {
              this.$store.dispatch('library/deleteVideoFromLibraryByVideoId', { library: this.library, videoId });
              this.$store.dispatch('playlist/deleteVideoFromPlaylistsByVideoId', videoId);
            })

            this.batchOperation = 'Batch operations';
            this.selectedVideoIds = [];
            this.$toast.success('Video(s) deleted successfully');
          })
          .finally(() => this.isLoading = false);
      }
    }
  }
};
</script>
