import User from '@/entities/User';
import UserService from '@/services/User';

const state = {
  list: [],
};

const getters = {
  list(state) {
    return state.list;
  },

  get: (state) => (id) => {
    for (let item of state.list) {
      if (item.id === id) {
        return item;
      }
    }

    return null;
  },
};

const actions = {
  getList(store) {
    return UserService.getList().then((resp) => {
      store.commit('setList', resp);
      return resp;
    });
  },

  get(store, id) {
    return UserService.get(id).then((resp) => {
      const responsedEntity = new User(resp);

      if (!store.getters.get(responsedEntity.id)) {
        store.commit('addToList', responsedEntity);
      } else {
        store.commit('updateInList', responsedEntity);
      }

      return responsedEntity;
    });
  },

  save(store, entity) {
    let promise;
    if (entity.isNew) {
      promise = UserService.create(entity);
    } else {
      promise = UserService.update(entity);
    }

    return promise.then((resp) => {
      const responsedEntity = new User(resp);

      if (entity.isNew) {
        store.commit('addToList', responsedEntity);
      } else {
        store.commit('updateInList', responsedEntity);
      }

      return responsedEntity;
    });
  },

  delete(store, entity) {
    return UserService.delete(entity)
      .then(() => {
        store.commit('deleteFromList', entity);
      });
  },
};

const mutations = {
  setList(state, list) {
    state.list = [];

    for (let item of list) {
      if (item instanceof User) {
        state.list.push(item);
      } else {
        state.list.push(new User(item));
      }
    }
  },

  addToList(state, entity) {
    state.list.push(entity);
  },

  updateInList(state, entity) {
    for (let item of state.list) {
      if (item.id !== entity.id) {
        continue;
      }

      item.updateWith(entity);

      break;
    }
  },

  deleteFromList(state, entity) {
    for (var i = 0; i < state.list.length; i++) {
      if (state.list[i].id === entity.id) {
        state.list.splice(i, 1);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
