var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "thumbnail-list" }, [
    _c("div", { staticClass: "active" }, [
      _c(
        "div",
        { class: { thumbnail: true, "is-loading": _vm.isLoading } },
        [
          _vm.isLoading
            ? _c("pulse-loader", {
                attrs: {
                  loading: _vm.isLoading,
                  color: "#04223e",
                  size: "6px",
                },
              })
            : _vm._e(),
          _c("img", { attrs: { src: _vm.video.thumbnail_url } }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "list" },
      [
        _vm._l(_vm.generatedThumbnails, function (thumbnail) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip.bottom",
                  value: "Set thumbnail",
                  expression: "'Set thumbnail'",
                  modifiers: { bottom: true },
                },
              ],
              key: thumbnail.id,
              staticClass: "thumbnail selectable",
              on: {
                click: function ($event) {
                  return _vm.selectThumbnail(thumbnail.id)
                },
              },
            },
            [_c("img", { attrs: { src: thumbnail.url } })]
          )
        }),
        _c(
          "div",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.bottom",
                value: "Upload new thumbnail",
                expression: "'Upload new thumbnail'",
                modifiers: { bottom: true },
              },
            ],
            staticClass: "thumbnail upload",
          },
          [
            _c("i", { staticClass: "fad fa-upload" }),
            _c("label", { attrs: { for: "new-thumbnail" } }),
            _c("input", {
              attrs: {
                type: "file",
                id: "new-thumbnail",
                accept: "image/png, image/gif, image/jpeg",
              },
              on: { change: _vm.uploadFile },
            }),
            _c("img", { attrs: { src: _vm.video.thumbnail_url } }),
          ]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }