import Vue from 'vue';
import Vuex from 'vuex';

import app from '@/store/modules/app';
import auth from '@/store/modules/auth';
import invites from '@/store/modules/invites';
import library from '@/store/modules/library';
import playlist from '@/store/modules/playlist';
import upload from '@/store/modules/upload';
import user from '@/store/modules/user';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    app,
    auth,
    invites,
    library,
    playlist,
    upload,
    user,
  },
  plugins: [],
});
