import VideoVariation from '@/entities/VideoVariation';
import VideoThumbnail from '@/entities/VideoThumbnail';

export default class Video {

  id = null;
  name = '';
  description = '';
  tags = [];
  status = null;
  views = null;
  width = null;
  height = null;
  length = null;
  filename = null;
  filesize = null;
  file_error_code = null;
  thumbnail_url = '';
  vast_tag_url = '';
  video_url = '';
  autoplay = false;
  muted = true;
  loop = false;
  disable_ads = false;
  max_resolution = null;

  playlists = [];
  thumbnails = [];

  constructor (rawData) {
    if (Object.prototype.toString.call(rawData) !== '[object Object]') {
      return;
    }

    this.updateWith(rawData);
  }

  get isNew() {
    return this.id === null;
  }

  updateWith(newValues) {
    for (const [key, value] of Object.entries(newValues)) {
      if (typeof this[key] === 'undefined') {
        continue;
      }

      if (key === 'tags') {
        this.tags = [];
        for (let item of value) {
          this.tags.push({ 'text': item, 'tiClasses': ['ti-valid'] });
        }
      }
      else if (key === 'variations') {
        for (let item of value) {
          const entity = new VideoVariation(item);
          this[key].push(entity);
        }
      }
      else if (key === 'thumbnails') {
        this.thumbnails = [];
        for (let item of value) {
          const entity = new VideoThumbnail(item);
          this[key].push(entity);
        }
      }
      else {
        this[key] = value;
      }
    }
  }

}
