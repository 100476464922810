var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "auth-page login-form-view" },
    [
      _c("logo"),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Log in to your account")]),
        _c(
          "form",
          {
            staticClass: "credentials",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submit.apply(null, arguments)
              },
            },
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email",
                },
              ],
              staticClass: "big",
              attrs: { type: "email", placeholder: "E-mail" },
              domProps: { value: _vm.email },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.email = $event.target.value
                },
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password",
                },
              ],
              staticClass: "big",
              attrs: { type: "password", placeholder: "Password" },
              domProps: { value: _vm.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.password = $event.target.value
                },
              },
            }),
            _c(
              "button",
              { staticClass: "button primary big", attrs: { type: "submit" } },
              [
                _vm.isLoading
                  ? _c("pulse-loader", {
                      attrs: {
                        loading: _vm.isLoading,
                        color: "#ffffff",
                        size: "6px",
                      },
                    })
                  : [_vm._v("Sign in")],
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "forgotten-password" },
              [
                _c("span", [_vm._v("Forgot password?")]),
                _c(
                  "router-link",
                  { attrs: { to: "/auth/forgotten-password" } },
                  [_vm._v("Click here!")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }