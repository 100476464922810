<template>
  <modal
    v-if="library"
    name="upload-video"
    class="upload-video-modal"
    height="auto"
  >
    <div ref="drop-zone" class="drop-zone"
      @drop.prevent="onDropFile"
      @dragenter.prevent="onDragEnter"
      @dragover.prevent="onDragEnter"
      @dragleave.prevent="onDragLeave"
      @dragend.prevent="onDragLeave"
    >
      <label for="file">
        <i class="fad fa-5x fa-file-upload"/>
        <h3>Upload Videos</h3>
        Drop your video file here, or <span class="orange">browse</span>
      </label>
      <input
        type="file"
        id="file"
        @change="uploadFile"
        accept=".mp4,.mkv,.webm,.flv,.vod,.avi,.mov,.wmv,.amv,.m4p,.mpeg,.mpg,.4mv"
      />
    </div>
    <div v-if="fileUploadStatus !== null" class="file-upload-status">
      <div v-if="fileUploadStatus === 'Uploading'" class="info">{{ uploadProgress + '%' }}</div>
      <div v-if="fileUploadStatus === 'Finished'" class="info finished"> <i class="fa fa-check"></i> </div>

      <div class="file-name">{{ file.name }}</div>
      <div class="status">{{ fileUploadStatus }}</div>
      <div class="progressbar"> <div :style="{ width: uploadProgress + '%' }"></div> </div>
    </div>
  </modal>
</template>

<script>
import Video from '@/entities/Video';
import VideoService from '@/services/Video';
import * as tus from "tus-js-client";

export default {
  name: 'VideoUploadModal',
  data: () => ({
    file: null,
    video: new Video(),
    fileUploadStatus: null,
  }),
  computed: {
    libraryId() {
      return this.$route.params.libraryId;
    },
    library() {
      return this.$store.getters['library/get'](this.libraryId);
    },
    isUploading() {
      return this.$store.getters['upload/isUploading'];
    },
    uploadProgress() {
      return this.$store.getters['upload/uploadProgress'];
    },
  },
  methods: {
    onDragEnter() {
      if (this.isUploading) {
        return null;
      }

      this.$refs['drop-zone'].classList.add('is-dragging');
    },
    onDragLeave() {
      if (this.isUploading) {
        return null;
      }

      this.$refs['drop-zone'].classList.remove('is-dragging');
    },
    onDropFile(e) {
      if (this.isUploading) {
        return null;
      }

      this.file = e.dataTransfer.files[0];
      this.$refs['drop-zone'].classList.remove('is-dragging');

      this.uploadFile();
    },
    uploadFile(e) {
      if (this.isUploading) {
        return null;
      }

      if (typeof e !== 'undefined') {
        this.file = e.target.files[0];
      }

      const _self = this;

      this.$store.dispatch('upload/setIsUploading', true);
      this.$store.dispatch('upload/setUploadProgress', 0);
      this.fileUploadStatus = 'Uploading';

      // Create video entity
      const sizeInMB = (this.file.size / 1024 / 1024).toFixed(2);
      this.video.filename = this.file.name;
      this.video.filesize = sizeInMB;

      VideoService.create(this.library.id, this.video).then((resp) => {
        this.video.updateWith(resp);

        // Tus upload
        const upload = new tus.Upload(this.file, {
          endpoint: process.env.VUE_APP_TUS_UPLOAD_ENDPOINT,
          retryDelays: [0, 1000, 3000, 5000],
          chunkSize: 10 * 1024 * 1024,
          removeFingerprintOnSuccess: true,
          metadata: {
            filename: _self.file.name,
            filesize: _self.file.size,
            video_id: _self.video.id,
          },
          onError: (error) => {
            console.log("Failed because: " + error)
          },
          onProgress: (bytesUploaded, bytesTotal) => {
            const uploadProgress = Math.floor(bytesUploaded / bytesTotal * 100);
            _self.$store.dispatch('upload/setUploadProgress', uploadProgress);
          },
          onBeforeRequest(req) {
            if (!req.getHeader('Upload-Length')) {
              req.setHeader('Upload-Length', _self.file.size);
            }
          },
          onSuccess() {
            _self.fileUploadStatus = 'Finished';
            _self.$store.dispatch('upload/setIsUploading', false);

            _self.$modal.hide('upload-video');
            _self.video.status = _self.$videoStatuses.in_progress;

            // Update or add video entity to library entity in store
            if (_self.$store.getters['library/libraryHasVideo'](_self.library, _self.video)) {
              _self.$store.dispatch('library/updateVideoInLibrary', { library: _self.library, video: _self.video });
            } else {
              _self.$store.dispatch('library/addVideoToLibrary', { library: _self.library, video: _self.video });
            }
          },
        });

        upload.findPreviousUploads().then((previousUploads) => {
          if (previousUploads.length) {
            upload.resumeFromPreviousUpload(previousUploads[0]);
          }

          upload.start();
        });
      });
    },
  }
}
</script>
